export const GridIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 0.5H0.75C0.336 0.5 0 0.836 0 1.25V4.25C0 4.664 0.336 5 0.75 5H3.75C4.164 5 4.5 4.664 4.5 4.25V1.25C4.5 0.836 4.164 0.5 3.75 0.5Z"
        fill={color}
      />
      <path
        d="M3.75 7.25H0.75C0.336 7.25 0 7.586 0 8V11C0 11.414 0.336 11.75 0.75 11.75H3.75C4.164 11.75 4.5 11.414 4.5 11V8C4.5 7.586 4.164 7.25 3.75 7.25Z"
        fill={color}
      />
      <path
        d="M3.75 14H0.75C0.336 14 0 14.336 0 14.75V17.75C0 18.164 0.336 18.5 0.75 18.5H3.75C4.164 18.5 4.5 18.164 4.5 17.75V14.75C4.5 14.336 4.164 14 3.75 14Z"
        fill={color}
      />
      <path
        d="M10.5 0.5H7.5C7.086 0.5 6.75 0.836 6.75 1.25V4.25C6.75 4.664 7.086 5 7.5 5H10.5C10.914 5 11.25 4.664 11.25 4.25V1.25C11.25 0.836 10.914 0.5 10.5 0.5Z"
        fill={color}
      />
      <path
        d="M10.5 7.25H7.5C7.086 7.25 6.75 7.586 6.75 8V11C6.75 11.414 7.086 11.75 7.5 11.75H10.5C10.914 11.75 11.25 11.414 11.25 11V8C11.25 7.586 10.914 7.25 10.5 7.25Z"
        fill={color}
      />
      <path
        d="M10.5 14H7.5C7.086 14 6.75 14.336 6.75 14.75V17.75C6.75 18.164 7.086 18.5 7.5 18.5H10.5C10.914 18.5 11.25 18.164 11.25 17.75V14.75C11.25 14.336 10.914 14 10.5 14Z"
        fill={color}
      />
      <path
        d="M17.25 0.5H14.25C13.836 0.5 13.5 0.836 13.5 1.25V4.25C13.5 4.664 13.836 5 14.25 5H17.25C17.664 5 18 4.664 18 4.25V1.25C18 0.836 17.664 0.5 17.25 0.5Z"
        fill={color}
      />
      <path
        d="M17.25 7.25H14.25C13.836 7.25 13.5 7.586 13.5 8V11C13.5 11.414 13.836 11.75 14.25 11.75H17.25C17.664 11.75 18 11.414 18 11V8C18 7.586 17.664 7.25 17.25 7.25Z"
        fill={color}
      />
      <path
        d="M17.25 14H14.25C13.836 14 13.5 14.336 13.5 14.75V17.75C13.5 18.164 13.836 18.5 14.25 18.5H17.25C17.664 18.5 18 18.164 18 17.75V14.75C18 14.336 17.664 14 17.25 14Z"
        fill={color}
      />
    </svg>
  );
};
