export const carambolaGDPRStatement = (
  <div>
    <div class="modal-header modal-header--orange"></div>
    <div class="modal-body">
      <div class="row">
        <div
          class="modal-message"
          ng-style="{'height': '{{gdprStatementModalHeight}}px'}"
        >
          <strong>
            The following text is a short, clear statement of Carambola's
            position regarding personal data and the GDPR. For any inquiries,
            please contact your success manager.
          </strong>
          <br />
          <br />
          <p>
            Carambola Ltd takes the following position on personal data of EU
            website/app users (“Users”)
          </p>
          <p>
            <strong>
              Carambola Ltd does not process, collect or deal in any way, with
              User personal data.
            </strong>
            This means that we are not regulated by the GDPR – because the GDPR
            only regulates the use of personal data, which we do not process,
            collect or deal with.
          </p>
          <p>
            <strong>Publishers and the GDPR:</strong> As an online Publisher,
            you may or may not decide that you want to deal with personal data.
            If you yourself collect or use personal data about Users you will
            have undertaken your own assessment of whether and to what extent
            you may need to comply with the GDPR. If you allow a third party to
            collect or use personal data about users, you may as well have
            undertaken your own assessment to ensure the third party provider is
            compliant with the GDPR to the extent they need to be so.
          </p>
          <p>
            Carambola Ltd does not interfere in either of these Publisher
            processes. So, if any of your partners have consent from users,
            Carambola Ltd does not interfere with (or request or need or
            otherwise use) that consent. If the ad provider does not have (or
            need) consent, again this is a mechanism that Carambola Ltd does not
            interfere with.
          </p>
          <p>
            <strong>Carambola's widget:</strong> Our widget contains two parts:
            interactive content and ads. The interactive content is merely
            contextual to the page's content and therefore does not rely (and
            cannot rely) on User personal data. In regard to ads, what we do is
            facilitate the placement of generic JavaScript code from an ad
            provider onto your site/app to allow a flow of information to pass
            between the ad provider and you. Any User personal information from
            you or your website/app <strong>does not </strong>go through us or
            our servers. It is important for us to stress that{" "}
            <strong>all</strong> our ad providers declared their compliance with
            GDPR. Also, upon request, we'll gladly review their list with you
            and forward to them any questions you may have regarding GDPR.
          </p>
          <p>
            <strong>Moving forward:</strong> By continuing to do business with
            us, you're approving the above. Our promise to you is that we do not
            (and will not) store or use any personal data of any User in our
            interactions with you. On that note, we wish to thank you for your
            partnership and reiterate our commitment to becoming a most valuable
            partner to you.
          </p>
          <p>Carambola Team</p>
        </div>
      </div>
    </div>
  </div>
);
