import { User } from "@auth0/auth0-react";
import client from "../client";

export const getUserPreferences = async (user: User, type: string) => {
  const res = await client.get(`user_preferences/`, {
    params: { user: user, type: type },
  });
  return res.data;
};

export const updateUserPreferences = async (user: User, type: string) => {
  const res = await client.post(`user_preferences/`, {
    user: user,
    type: type,
  });
  return res.data;
};
