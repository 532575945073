import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import Loading from "../../components/Loading/Loading";
function Login() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);
  return null;
}

export default Login;
