import { useAuth0 } from "@auth0/auth0-react";
import Modal from "../components/Modal/Modal";
import { Row } from "antd";

const SessionOverModal = () => {
  const { logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <Modal
      width={"50%"}
      title={<h1>Session Expired</h1>}
      isModalOpen={true}
      setIsModalOpen={() => {}}
      okText={"REFRESH"}
      onOk={() => {
        // logoutWithRedirect();
        window.location.reload();
      }}
      hideCncelButton
      removeCloseButton={true}
    >
      <Row>
        <h2>Session expired. Please Refresh the page.</h2>
      </Row>
    </Modal>
  );
};

export default SessionOverModal;
