import { Bar } from "react-chartjs-2";
import { Col, Row, Skeleton } from "antd";
import Select from "../../components/Select/Select";
import { CheckCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getCardValue } from "../../utils/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface PerformanceChartProps {
  data: any;
  isLoading: boolean;
  platform: string;
}

const PerformanceChart = (props: PerformanceChartProps) => {
  const { data, isLoading, platform } = props;
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    setSelectedFiltersData();
  }, [data]);

  const setSelectedFiltersData = () => {
    if (data) {
      let tmp: string[] = [];
      tmp.push(data.IntervalDataTitles[0]);
      tmp.push(data.IntervalDataTitles[1]);
      setSelectedFilters(tmp);
    }
  };

  const getValuesByLabel = (label: string) => {
    let values = [];
    let labels = [];
    for (let item of data.IntervalData) {
      for (let intervalItem of item.data)
        if (intervalItem.Label === label) {
          let value = getCardValue(intervalItem, platform);
          values.push(value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ""));
          labels.push(dayjs.utc(item.date).format("MM/DD"));
        }
    }
    return { values, labels };
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          callback: function (value: any) {
            if (
              selectedFilters[0] == "Viewability" ||
              selectedFilters[0] == "Engagement"
            )
              return value + "%";
            else if (
              selectedFilters[0] == "Revenue" ||
              selectedFilters[0] == "CPM"
            )
              return value + "$";
            else return value;
          },
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        ticks: {
          callback: function (value: any) {
            if (
              selectedFilters[1] == "Viewability" ||
              selectedFilters[1] == "Engagement"
            )
              return value + "%";
            else if (
              selectedFilters[1] == "Revenue" ||
              selectedFilters[1] == "CPM"
            )
              return value + "$";
            else return value;
          },
        },
      },
    },
  };

  const getData = () => {
    const chartDta = {
      labels: getValuesByLabel(selectedFilters[0]).labels,
      datasets: [
        {
          label: selectedFilters[0],
          data: getValuesByLabel(selectedFilters[0]).values,
          backgroundColor: "rgba(253, 184, 19, 0.5)",
          yAxisID: "y",
        },
        {
          label: selectedFilters[1],
          data: getValuesByLabel(selectedFilters[1]).values,
          backgroundColor: "rgba(64, 151, 255, 0.5)",
          yAxisID: "y1",
        },
      ],
    };
    return chartDta;
  };

  const getOptions = (selectNumber: number) => {
    const intervalDataTitles = data.IntervalDataTitles.filter(
      (item: string) => item != selectedFilters[selectNumber == 0 ? 1 : 0]
    ).map((item: string) => {
      return { label: item, value: item };
    });

    if(selectNumber === 1) {
     if(intervalDataTitles.length > 0 && (intervalDataTitles[0].value === "Monetized Page Views" || intervalDataTitles[0].value === "Widget Loads")) intervalDataTitles.shift();
    }
    
    return intervalDataTitles;
  };

  return (
    <Row gutter={16}>
      {isLoading || selectedFilters.length < 2 || !data ? (
        <Skeleton loading={true} avatar active></Skeleton>
      ) : (
        <>
          <Col span={6}>
            <Select
              options={getOptions(0)}
              defaultValue={getOptions(0)[0]}
              onChange={(value: string) => {
                let tmp: string[] = [...selectedFilters];
                tmp[0] = value;
                setSelectedFilters(tmp);
              }}
              suffixIcon={
                <CheckCircleFilled
                  style={{
                    color: "rgba(253, 184, 19, 0.5)",
                    background: "rgba(253, 184, 19, 0.5)",
                    borderRadius: "50%",
                  }}
                />
              }
            />
          </Col>
          <Col span={6}>
            <Select
              options={getOptions(1)}
              onChange={(value: string) => {
                let tmp: string[] = [...selectedFilters];
                tmp[1] = value;
                setSelectedFilters(tmp);
              }}
              defaultValue={getOptions(1)[0]}
              suffixIcon={
                <CheckCircleFilled
                  style={{
                    color: "rgba(64, 151, 255, 0.5)",
                    background: "rgba(64, 151, 255, 0.5)",
                    borderRadius: "50%",
                  }}
                />
              }
            />
          </Col>
          <Bar style={{ marginTop: 24 }} options={options} data={getData()} />
        </>
      )}
    </Row>
  );
};

export default PerformanceChart;
