import client from "../client";

export const getDashboardData = async (
  pub_id: number,
  startDate: any,
  endDate: any,
  platformType: number,
  domain_ids: any[],
  pub_key: string
) => {
  const res = await client.post(`dashboard`, {
    params: {
      pub_id,
      startDate,
      endDate,
      platformType,
      pub_key,
    },
    domain_ids: domain_ids,
  });
  return res.data;
};

export const insertMetric = async (data: any) => {
  const res = await client.post(`dashboard/metric`, data);
  return res.data;
};

export const deleteMetric = async (data: any) => {
  const res = await client.delete(`dashboard/metric`, {
    data: data,
  });
  return res.data;
};
