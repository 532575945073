import React, { useEffect, useState } from "react";
import { Row, Table, Tooltip, Tag, DatePicker, Input, Select } from "antd";
import Button from "../../components/Button";
import PureCard from "../../components/PureCard/PureCard";
import { ActivityLog, LogFilter } from "../../utils/types";
import "./styles.scss";
import { getActivityLog, getUsersEmails } from "../../api/services/ActivityLog";
import { FormatDateConvert } from "../../../src/utils/utils";

const { RangePicker } = DatePicker;

const ACtivityLog = () => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 20);
  const [originalData, setOriginalData] = useState<ActivityLog[]>([]);
  const [dataLog, setDataLog] = useState<ActivityLog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allUserEmails, setAllUserEmails] = useState<string[]>([]);
  const [logFilter, setLogFilter] = useState<LogFilter>({
    startDate: undefined,
    endDate: undefined,
    userEmail: undefined,
    actionType: undefined,
    action_context: undefined,
  });

  const actionColors: any = {
    DELETE: "red",
    INSERT: "blue",
    UPDATE: "orange",
  };
  const getActionColor = (actionType: string) => {
    return actionColors[actionType.toUpperCase()] || "green";
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    let response = await getActivityLog();
    const originalData: ActivityLog[] = response.map((log: ActivityLog) => {
      const dateObject = new Date(log.modification_time);
      const formattedDate = FormatDateConvert(dateObject);

      return {
        id: log.id,
        modification_time: formattedDate,
        user_email: log.user_email,
        action_type: log.action_type,
        details: log.details,
        action_context: log.action_context,
      };
    });
    setDataLog(originalData);
    setOriginalData(originalData);
    const allemails = await getUsersEmails();
    setAllUserEmails(allemails);
    setIsLoading(false);
  };

  useEffect(() => {
    applyFilters();
  }, [logFilter]);

  const applyFilters = () => {
    let filteredData = originalData;

    if (logFilter.userEmail) {
      filteredData = filteredData.filter(
        (log) => log.user_email === logFilter.userEmail
      );
    }

    if (logFilter.actionType) {
      filteredData = filteredData.filter(
        (log) =>
          log.action_type.includes(logFilter.actionType!.toUpperCase()) ||
          log.action_type.includes(logFilter.actionType!.toLowerCase())
      );
    }
    if (logFilter.action_context) {
      filteredData = filteredData.filter(
        (log) =>
          log.action_context.includes(
            logFilter.action_context!.toUpperCase()
          ) ||
          log.action_context.includes(logFilter.action_context!.toLowerCase())
      );
    }

    const startDate = logFilter.startDate
      ? new Date(logFilter.startDate)
      : new Date(0);

    const endDate = logFilter.endDate
      ? new Date(logFilter.endDate)
      : new Date();

    filteredData = filteredData.filter((log) => {
      debugger;
      const logDate = new Date(log.modification_time);
      if (logDate >= startDate && logDate <= endDate)
        return logDate >= startDate && logDate <= endDate;
    });
    setDataLog(filteredData);
  };

  const getColumns = () => {
    return [
      {
        title: "Time",
        key: "modification_time",
        dataIndex: "modification_time",
        width: "20%",
      },
      {
        title: "User Email",
        dataIndex: "user_email",
        width: "18%",
      },

      {
        title: "Action",
        key: "action_type",
        dataIndex: "action_type",
        width: "14%",
        render: (text: string) => {
          text = text.toUpperCase();
          text =
            text.includes("UPDATE") || text.includes("SET") ? "UPDATE" : text;
          text = text.includes("GET") ? "GET" : text;

          return (
            <Tag bordered={false} color={getActionColor(text)}>
              {text.toUpperCase()}
            </Tag>
          );
        },
      },
      {
        title: "Details",
        key: "details",
        dataIndex: "details",
        width: "35%",
        ellipsis: true,
        render: (text: string) => (
          <Tooltip title={text} placement="topLeft">
            {text}
          </Tooltip>
        ),
      },
      {
        title: "Action Context",
        dataIndex: "action_context",
        width: "18%",
      },
    ];
  };

  const onChangeDate: any = (dates: any, datesString: any) => {
    setLogFilter({
      ...logFilter,
      startDate: datesString[0],
      endDate: datesString[1],
    });
  };

  const onSelectEmail = (email: string) => {
    setLogFilter({
      ...logFilter,
      userEmail: email,
    });
  };

  const onChangeAction = (action: string) => {
    setLogFilter({
      ...logFilter,
      actionType: action,
    });
  };

  const onChangeContext = (context: string) => {
    setLogFilter({
      ...logFilter,
      action_context: context,
    });
  };

  return (
    <div className="activity-log">
      <PureCard>
        <Row
          justify={"start"}
          style={{ marginBottom: 24, justifyContent: "space-between" }}
        >
          <RangePicker
            style={{ width: "36%" }}
            onChange={onChangeDate}
            showTime
          />
          <Select
            allowClear
            placeholder="Select User Email"
            options={
              allUserEmails &&
              allUserEmails.map((email: any) => {
                return { value: email.user_email, label: email.user_email };
              })
            }
            size="large"
            onSelect={(email) => {
              onSelectEmail(email);
            }}
            onClear={() => {
              onSelectEmail("");
            }}
          />

          <Input
            allowClear
            style={{ width: "16%" }}
            placeholder="Action"
            onChange={(e) => onChangeAction(e.target.value)}
          />

          <Input
            allowClear
            style={{ width: "16%" }}
            placeholder="Action Context"
            onChange={(e) => onChangeContext(e.target.value)}
          />
        </Row>

        <Table
          columns={getColumns()}
          dataSource={dataLog}
          loading={isLoading}
          rowKey={"id"}
        />
      </PureCard>
    </div>
  );
};

export default ACtivityLog;
