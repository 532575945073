import { useState } from "react";
import Button from "../../components/Button";
import { Image, List, Row, Table } from "antd";
import { Input } from "antd";
import { getEmbeds } from "../../api/services/Domains";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import PureCard from "../../components/PureCard/PureCard";
import instagramIcon from "../../assets/images/instagram.svg";
import twitterIcon from "../../assets/images/twitter.svg";
import "./styles.scss";
import { AppState } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

const EmbedScaner = () => {
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [urls, setUrls] = useState<string[]>([]);
  const [url, setUrl] = useState<string>("");

  const getColumns = () => {
    return [
      {
        title: "Url",
        key: "url",
        dataIndex: "url",
      },
      {
        title: "Platforms",
        key: "platforms",
        dataIndex: "platforms",
      },
    ];
  };

  const getDataSource = () => {
    let tmp: any[] = [];
    for (let index = 0; index < data.length; index++) {
      let item = data[index];
      tmp.push({
        key: index.toString(),
        url: item.url,
        platforms: (
          <Row className="platforms">
            {item.instagram && (
              <Image
                src={instagramIcon}
                preview={false}
                style={{ width: 40 }}
              />
            )}
            {item.twitter && (
              <Image src={twitterIcon} preview={false} style={{ width: 40 }} />
            )}
            {item.error && <h3>Error</h3>}
          </Row>
        ),
      });
    }
    return tmp;
  };

  const addUrl = () => {
    let tmp = [...urls];
    tmp.push(url);
    setUrls(tmp);
    setUrl("");
  };
  const deleteUrl = (index: number) => {
    let tmp = [...urls];
    tmp.splice(index, 1);
    setUrls(tmp);
  };
  const scan = async () => {
    setData([]);
    setShowTable(true);
    let data = await getEmbeds(urls);
    setData(data);
    setUrls([]);
  };
  return (
    <Row className="embed-scanner-wrapper">
      <Row className="title">
        <h2> Welcome to Embed Scanner</h2>
      </Row>
      <Row className="container">
        <Input
          style={{ height: "50px" }}
          size="large"
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);
          }}
          placeholder="paste your url here and clicl enter"
          onKeyDown={(e) => {
            if (e.code === "Enter" && url != "") addUrl();
          }}
        />

        {urls.length > 0 && (
          <Row className="urls-wrapper">
            <List
              style={{ width: "100%" }}
              itemLayout="horizontal"
              dataSource={urls}
              renderItem={(item, index) => (
                <List.Item
                  style={{ background: isDarkMode ? "inherit" : "white" }}
                  className="list-item"
                  actions={[
                    <a onClick={() => deleteUrl(index)}>
                      <DeleteOutlined />
                    </a>,
                  ]}
                >
                  <List.Item.Meta
                    style={{
                      width: "90%",
                    }}
                    description={item}
                  />
                </List.Item>
              )}
            />
          </Row>
        )}
      </Row>
      <Button
        title="Start to scan"
        type="primary"
        size="large"
        onClick={scan}
        icon={<SearchOutlined />}
        disabled={urls.length === 0}
      ></Button>

      {showTable && (
        <PureCard isLoading={data.length === 0}>
          <Table dataSource={getDataSource()} columns={getColumns()} />
        </PureCard>
      )}
    </Row>
  );
};

export default EmbedScaner;
