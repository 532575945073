import { useState } from "react";
import Button from "../../components/Button";
import { Row, Skeleton, Tag } from "antd";
import "./styles.scss";
import { PlusCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

interface InstegramUnitProps {
  index: number;
  code: string;
  onUnitClicked?: (
    code: string,
    platform: string,
    hashtag: string,
    id?: number
  ) => void;
  selectedItems: number[];
  showSelectButton?: boolean;
  width?: string;
  height?: string;
  hashtag?: string;
  tagColor?: string;
  id?: number;
}

const InstegramUnit = (props: InstegramUnitProps) => {
  const {
    index,
    code,
    onUnitClicked,
    selectedItems,
    showSelectButton,
    width,
    height,
    hashtag,
    tagColor,
    id,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  return (
    <div
      id="iframe"
      style={{
        width: width || "15%",
        display: "flex",
        flexDirection: "column",
        marginTop: "16px",
      }}
    >
      {(isLoading || code === "") && (
        <Skeleton.Input
          active
          style={{
            height: "250px",
            width: "100%",
            marginBottom: "24px",
          }}
        />
      )}

      {code != "" && (
        <iframe
          style={{ height: height || "250px" }}
          onLoad={() => setIsLoading(false)}
          className={isLoading ? "iframe-loading" : "iframe-display"}
          src={`https://www.instagram.com/p/${code}/embed/`}
        ></iframe>
      )}

      <Row style={{ justifyContent: "center", gap: "16px" }}>
        {showSelectButton && (
          <Button
            disabled={isLoading || code === ""}
            style={{ width: "100%" }}
            icon={
              selectedItems.some(
                (item: any) =>
                  item.code === code && item.platform === "instagram"
              ) ? (
                <CheckCircleOutlined />
              ) : (
                <PlusCircleOutlined />
              )
            }
            type="primary"
            onClick={() =>
              onUnitClicked &&
              hashtag &&
              onUnitClicked(code, "instagram", hashtag, id)
            }
            title={selectedItems.includes(index) ? "Added" : "Select Post"}
            size="large"
            className={
              selectedItems.some(
                (item: any) =>
                  item.code === code && item.platform === "instagram"
              )
                ? "after-select"
                : "before-select"
            }
          />
        )}
        {hashtag && tagColor && (
          <Tag style={{ borderRadius: "50px" }} color={tagColor}>
            #{hashtag}
          </Tag>
        )}
      </Row>
    </div>
  );
};

export default InstegramUnit;
