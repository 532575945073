import { useState } from "react";
import Button from "../../components/Button";
import { Col, Divider, Row, Image, notification } from "antd";
import "./styles.scss";

import InstegramUnit from "./InstegramUnit";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { useAuth0 } from "@auth0/auth0-react";
import { getSocialDataByDomain, removePosts } from "../../api/services/Unit";
import { NotificationType } from "../../utils/notifictions";

import "react-alice-carousel/lib/alice-carousel.css";
import PublisherSelect from "../DomainSelection/PublisherSelect";
import { sendGtmEvent } from "../../gtm";
import instagramIcon from "../../assets/images/instagram.svg";
import tiktokIcon from "../../assets/images/tiktok.svg";
import twitterIcon from "../../assets/images/twitter.svg";
import TiktokUnit from "./TiktokUnit";
import {
  capitalizeFirstLetter,
  checkPermissionOk,
  getRandomColor,
} from "../../utils/utils";
import DomainSelect from "../DomainSelection/DomainSelect";
import TwitterUnit from "./TwitterUnit";
import { IDS } from "../../utils/elementsIds";

type Stages = "discover" | "result" | "loading";

const History = () => {
  const [data, setData] = useState<any>(undefined);
  const [tagsColors, setTagsColors] = useState<any>({});
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const { permissions } = useSelector((state: AppState) => state);
  const [api, contextHolder] = notification.useNotification();
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const [stage, setStage] = useState<Stages>("discover");
  const [domain, setDomain] = useState<string>("");

  const { user } = useAuth0();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const onUnitClicked = (
    code: string,
    platform: string,
    hashtag: string,
    id?: number
  ) => {
    let tmp = [...selectedItems];
    let index = tmp.findIndex(
      (item: any) => item.code === code && item.platform === platform
    );
    if (index === -1) {
      let obj = { code, platform, hashtag, id };
      tmp.push(obj);
    } else {
      tmp.splice(index, 1);
    }
    setSelectedItems(tmp);
  };

  const countByPlatform = () => {
    let count: any = {};
    for (let item of selectedItems) {
      if (count[item.platform]) {
        count[item.platform]++;
      } else {
        count[item.platform] = 1;
      }
    }
    return count;
  };

  const remove = async () => {
    let countObj = countByPlatform();
    let keysWithValueGreaterThan4 = Object.keys(countObj).filter(
      (key) =>
        data[key].length - countObj[key] < 4 &&
        data[key].length - countObj[key] != 0
    );
    if (keysWithValueGreaterThan4.length > 0) {
      openNotificationWithIcon(
        "error",
        `please leave at least 4 posts for platforms ${JSON.stringify(
          keysWithValueGreaterThan4
        )}`
      );
    } else {
      let postsForRemove = selectedItems.map((obj: any) => obj.id);
      await removePosts(postsForRemove);
      await getData();
      openNotificationWithIcon("success", "posts removed successfully");
      sendGtmEvent(`Save Social units Posts`, user);
    }
  };

  const getData = async () => {
    setSelectedItems([]);
    let data = {
      domain_id: domain,
    };
    const response = await getSocialDataByDomain(data);
    setData(response);
  };

  const getPlatformIcon = (platform: string) => {
    if (platform === "instagram") return instagramIcon;
    if (platform === "tiktok") return tiktokIcon;
    if (platform === "twitter") return twitterIcon;
  };

  const getTagColor = (hashtag: string) => {
    if (tagsColors[hashtag]) {
      return tagsColors[hashtag];
    } else {
      let color = getRandomColor();
      let tmp = tagsColors;
      tmp[hashtag] = color;
      setTagsColors(tmp);
      return color;
    }
  };
  return (
    <Row className="unit-select">
      <Row justify="space-between" style={{ width: "100%" }}>
        <Col className="title">
          <div style={{ color: isDarkMode ? "#FDB813" : "#4097FF" }}>
            Social Unit History
          </div>
        </Col>
      </Row>
      <Row className="unit-history-header">
        {permissions && checkPermissionOk("read:domains", permissions) && (
          <Col span={5}>
            <PublisherSelect id={IDS.MANAGMENT.PUBLISHER_SELECT} />
          </Col>
        )}
        <Col span={6}>
          <DomainSelect
            id={IDS.MANAGMENT.DOMAIN_SELECT}
            onSelect={(value: any) => {
              setDomain(value);
            }}
          />
        </Col>
        <Col>
          <Button
            id={IDS.MANAGMENT.SHOW_BUTTON}
            disabled={domain === ""}
            title="Show History"
            onClick={async () => {
              setStage("loading");
              await getData();
              setStage("result");
            }}
            type="primary"
            size="large"
          />
        </Col>

        <Col>
          <Button
            permission="update:unitSelect"
            id={IDS.MANAGMENT.REMOVE_BUTTON}
            disabled={domain === "" || selectedItems.length === 0}
            title="Remove posts"
            onClick={remove}
            type="primary"
            size="large"
          />
        </Col>
      </Row>

      <Divider />

      {stage === "discover" && <Row></Row>}

      {stage === "result" && (
        <Row style={{ width: "100%" }}>
          {data &&
            Object.entries(data).map(
              ([platform, platformData]: [string, any], index) => {
                return (
                  <Row style={{ width: "100%" }} key={index}>
                    <Row
                      id={
                        platform === "instagram"
                          ? IDS.MANAGMENT.INSTAGRAM_CONTAINER
                          : platform === "tiktok"
                          ? IDS.MANAGMENT.TIKTOK_CONTAINER
                          : IDS.MANAGMENT.TWITTER_CONTAINER
                      }
                      style={{
                        gap: "16px",
                        background: "",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={getPlatformIcon(platform)}
                        preview={false}
                        style={{ width: 40 }}
                      />
                      <h2>{`${capitalizeFirstLetter(platform)} Unit`}</h2>
                    </Row>

                    {
                      <Row
                        key={index}
                        style={{
                          gap: "20px",
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          overflow: "scroll",
                          marginTop: "16px",
                          marginBottom: "70px",
                        }}
                      >
                        {platformData.map((item: any, index: number) => {
                          if (platform === "instagram")
                            return (
                              <InstegramUnit
                                key={index}
                                index={index}
                                code={item.permalink}
                                onUnitClicked={onUnitClicked}
                                selectedItems={selectedItems}
                                showSelectButton
                                hashtag={item.hashtag_name}
                                tagColor={getTagColor(item.hashtag_name)}
                                id={item.id}
                              />
                            );
                          else if (platform === "tiktok")
                            return (
                              <TiktokUnit
                                key={index}
                                index={index}
                                code={item.permalink}
                                onUnitClicked={onUnitClicked}
                                selectedItems={selectedItems}
                                showSelectButton
                                hashtag={item.hashtag_name}
                                tagColor={getTagColor(item.hashtag_name)}
                                id={item.id}
                              />
                            );
                          else if (platform === "twitter")
                            return (
                              <TwitterUnit
                                key={index}
                                index={index}
                                code={item.permalink}
                                onUnitClicked={onUnitClicked}
                                selectedItems={selectedItems}
                                showSelectButton
                                hashtag={item.hashtag_name}
                                tagColor={getTagColor(item.hashtag_name)}
                                id={item.id}
                              />
                            );
                          else return null;
                        })}
                      </Row>
                    }
                  </Row>
                );
              }
            )}
        </Row>
      )}

      {stage === "loading" && (
        <Row
          style={{
            gap: "16px",
            background: "",
            alignItems: "center",
          }}
        >
          {Array(12)
            .fill("")
            .map((i, index) => {
              return (
                <InstegramUnit
                  key={index}
                  index={index}
                  code={""}
                  onUnitClicked={onUnitClicked}
                  selectedItems={selectedItems}
                  showSelectButton
                />
              );
            })}
        </Row>
      )}
      {contextHolder}
    </Row>
  );
};

export default History;
