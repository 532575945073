import { Auth0Provider } from "@auth0/auth0-react";

import App from "../App";

const Auth0 = () => {
  const providerConfig = {
    domain: "dev-ovcxuhr3lpxgrden.us.auth0.com",
    clientId: "H8YNEIAlSA5njpv0fF2FCbMUbFH712dV",
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://dev-ovcxuhr3lpxgrden.us.auth0.com/api/v2/",
    },
  };

  return (
    <div>
      <Auth0Provider {...providerConfig}>
        <App />
      </Auth0Provider>
    </div>
  );
};

export default Auth0;
