import client from "../client";

export const getWidgetsSummary = async (pub_id: number, domain_id: number) => {
  const res = await client.get(`/widgets/${pub_id}/${domain_id}`);
  return res.data;
};
export const getAllWidgetsNumbers = async () => {
  const res = await client.get(`/widgets`);
  return res.data;
};
export const getAllWidgetsData = async (pub_id: number, domain_id: number) => {
  const res = await client.get(`/widgets/data/${pub_id}/${domain_id}`);
  return res.data;
};
export const getAllLayoutTypes = async () => {
  const res = await client.get(`/widgets/layouts`);
  return res.data;
};
export const getAllItemsTypes = async () => {
  const res = await client.get(`/widgets/items`);
  return res.data;
};
export const getAllDefaultAiCategories = async () => {
  const res = await client.get(`/widgets/default_ai_categories`);
  return res.data;

};

export const insertWidgetBatch = async (
  widgets: any[],
  domain_id: number,
  pub_key: string
) => {
  const res = await client.post(`/widgets/insert`, {
    widgets: widgets,
    domain_id: domain_id,
    pub_key: pub_key,
  });

  return res.data;
};

export const updateWidgetBatch = async (
  widgets: any[],
  domain_id: number,
  pub_key: string
) => {
  const res = await client.post(`/widgets/update_batch`, {
    widgets: widgets,
    domain_id: domain_id,
    pub_key: pub_key,
  });

  return res.data;
};


export const deleteWidget = async (
  pub_key: string,
  pub_id: number,
  domain_id: number,
  id: number,
  widget_id: number
) => {
  const res = await client.delete(`/widgets/delete`, {
    data: {
      id,
      widget_id,
      pub_id,
      pub_key,
      domain_id,
    },
  });
  return res.data;
};
