import React, { useEffect, useState } from "react";
import { Input, Popconfirm, Row, Space, Table, Tag, notification } from "antd";
import {
  addActiveDomains,
  deleteActiveDomain,
  getActiveDomains,
  getAllDomains,
} from "../../api/services/Domains";
import dayjs from "dayjs";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import DomainsModal from "../../modals/DomainsModal";
import Button from "../../components/Button";
import PureCard from "../../components/PureCard/PureCard";
import { NotificationType } from "../../utils/notifictions";
import { checkPermissionOk } from "../../utils/utils";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";

const ActiveDomainsManager = () => {
  const [data, setData] = useState<any[]>([]);
  const [options, setOptions] = useState<any[] | null>(null);
  const [isActiveDomainOpen, setIsActiveDomainOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mapOptions, setMapOptions] = useState<any>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const { permissions } = useSelector((state: AppState) => state);

  useEffect(() => {
    getData();
  }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const getData = async () => {
    setIsLoading(true);
    let response = await getActiveDomains();
    setData(response);
    setIsLoading(false);
    getallDomainesUrl();
  };

  const getMapOptions = (response: any) => {
    let tmpMapOptions: any = {};
    for (let i = 0; i < response.length; i++) {
      tmpMapOptions[response[i].name] = response[i].id;
    }
    setMapOptions(tmpMapOptions);
  };
  const getColumns = () => {
    let cols = [
      {
        title: "Domain",
        key: "domain",
        dataIndex: "domain",
      },

      {
        title: "Created Date",
        key: "date",
        dataIndex: "date",
      },
    ];

    if (permissions && checkPermissionOk("delete:activeDomains", permissions))
      cols.push({
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
      });
    return cols;
  };

  const getallDomainesUrl = async () => {
    let response = await getAllDomains();
    getMapOptions(response);
    let tmpOptions = [];
    for (let i = 0; i < response.length; i++) {
      tmpOptions.push({
        label: response[i].name,
        value: response[i].name,
      });
    }
    setOptions(tmpOptions);
  };

  const getDataSource = () => {
    let tmp: any[] = [];
    for (let index = 0; index < data.length; index++) {
      let item = data[index];
      if (item.name.toLowerCase().includes(searchValue.toLocaleLowerCase())) {
        tmp.push({
          key: index.toString(),
          domain: item.name,
          date: dayjs(item.created_datet).format("MM/DD/YYYY"),
          actions: permissions &&
            checkPermissionOk("delete:activeDomains", permissions) && (
              <Row
                style={{
                  fontSize: "18px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                }}
              >
                <Popconfirm
                  title="Delete"
                  style={{ background: "red" }}
                  description="Are you sure yo want to delete the domain?"
                  placement="topLeft"
                  onConfirm={() => onDeleteActiveDomain(item.ref_domain_id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Row>
            ),
        });
      }
    }
    return tmp;
  };
  const addDomains = async (selectedItems: string[]) => {
    for (let domain of selectedItems) {
      let isExsist = data.find((element) => element.ref_domain_id === domain);
      if (isExsist) {
        selectedItems.splice(selectedItems.indexOf(domain), 1);
      }
    }
    await addActiveDomains(selectedItems);
    openNotificationWithIcon("success", "Domains added successfully");
    getData();
  };

  const onDeleteActiveDomain = async (ref_domain_id: any) => {
    await deleteActiveDomain(ref_domain_id);
    openNotificationWithIcon("success", "Domains deleted successfully");
    getData();
  };

  return options ? (
    <div
      style={{
        height: "100vh",
        width: "70%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {contextHolder}
      <Row justify={"end"} style={{ marginBottom: 24 }}>
        <Button
          title="Add Domain"
          type="primary"
          onClick={() => setIsActiveDomainOpen(true)}
        ></Button>
      </Row>
      <PureCard isLoading={isLoading}>
        <Input
          addonBefore={<SearchOutlined />}
          size="large"
          style={{ marginBottom: 24, width: "50%" }}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Table columns={getColumns()} dataSource={getDataSource()} />
      </PureCard>
      {isActiveDomainOpen && (
        <DomainsModal
          onClose={() => setIsActiveDomainOpen(false)}
          options={options}
          onOk={addDomains}
          mapOptions={mapOptions}
        />
      )}
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
      }}
    ></div>
  );
};

export default ActiveDomainsManager;
