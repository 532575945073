import axios from "axios";
import store from "../store/store";

const client = axios.create({
  baseURL: "https://dashboardapi.carambo.la/api",
});


client.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // window.location.reload();
      // localStorage.setItem("domain", "{}");
      store.dispatch({ type: "SET_SHOW_LOGOUT_MODAL", payload: true });
      try {
      } catch (error) {}
    }

    if (error.response.status === 402) {
      store.dispatch({
        type: "SET_SHOW_NO_PERMISSION_MODAL",
        payload: { show: true, message: error.response.data.message },
      });
    }
    // return error;
  }
);
export default client;
