import { useState } from "react";
import Modal from "../components/Modal/Modal";
import { carambolaGDPRStatement } from "../views/partials/carambolaGDPRStatement";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import { updateGdprByPubId } from "../api/services/Gdpr";

interface GdprModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  pub_id: number;
}

const UserActivityTypes = {
  GDPR_CONSENT_DISPLAYED: 14,
  GDPR_CONSENT_APPROVED_IS_EU: 16,
  GDPR_CONSENT_APPROVED_NONE_EU: 15,
};

const GdprModal = (props: GdprModalProps) => {
  const { onClose, isModalOpen, pub_id } = props;
  const [value, setValue] = useState<number>(0);
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const onApproval = async () => {
    await updateGdprByPubId(pub_id, value);
    onClose();
  };
  return (
    <Modal
      width={"50%"}
      title={<h2>Carambola Statement for Publishers – GDPR </h2>}
      isModalOpen={isModalOpen}
      setIsModalOpen={() => {}}
      okText={"I approve"}
      hideCncelButton
      okDisabled={value === 0}
      onOk={onApproval}
    >
      {carambolaGDPRStatement}
      {
        <>
          <h3>
            For the purpose of our own compliance, please answer the following
            question: Is your company incorporated in the European Economic Area
            (EEA)?
          </h3>
          <Radio.Group onChange={(e) => onChange(e)} value={value}>
            <Radio value={UserActivityTypes.GDPR_CONSENT_APPROVED_IS_EU}>
              Yes
            </Radio>
            <Radio value={UserActivityTypes.GDPR_CONSENT_APPROVED_NONE_EU}>
              No
            </Radio>
          </Radio.Group>
        </>
      }
    </Modal>
  );
};

export default GdprModal;
