const ads_Txt = `33across.com,0013300001qjCkpAAE,RESELLER,bbea06d9c4d2853c
admanmedia.com,43,RESELLER
adtech.com,10334,RESELLER
adtech.com,12068,RESELLER,e1a5b5b6e3255540
adyoulike.com,b4bf4fdd9b0b915f746f6747ff432bde,RESELLER
aol.com,55011,RESELLER,e1a5b5b6e3255540
appnexus.com,1001,RESELLER,f5ab79cb980f11d1
appnexus.com,10239,RESELLER,f5ab79cb980f11d1
appnexus.com,1356,RESELLER,f5ab79cb980f11d1
appnexus.com,1360,RESELLER,f5ab79cb980f11d1
appnexus.com,1908,RESELLER,f5ab79cb980f11d1
appnexus.com,3135,RESELLER,f5ab79cb980f11d1
appnexus.com,3153,RESELLER,f5ab79cb980f11d1
appnexus.com,3663,RESELLER,f5ab79cb980f11d1
appnexus.com,3703,RESELLER,f5ab79cb980f11d1
appnexus.com,4052,RESELLER,f5ab79cb980f11d1
appnexus.com,7396,RESELLER,f5ab79cb980f11d1
aps.amazon.com,0b2db9a2-c936-4557-a140-fbff93318c8c,RESELLER
aps.amazon.com,2840f06c-5d89-4853-a03e-3bfa567dd33c,RESELLER
axonix.com,57264,RESELLER
contextweb.com,560288,RESELLER,89ff185a4c4e857c
contextweb.com,561998,RESELLER,89ff185a4c4e857c
conversantmedia.com,40917,RESELLER,03113cd04947736d
districtm.io,100962,RESELLER,3fd707be9c4527c3
districtm.io,101760,RESELLER,3fd707be9c4527c3
emxdgt.com,407,RESELLER,1e1d41537f7cad7f
google.com,pub-5995202563537249,RESELLER,f08c47fec0942fa0
google.com,pub-9557089510405422,RESELLER,f08c47fec0942fa0
gumgum.com,11645,RESELLER,ffdef49475d318a9
indexexchange.com,175407,RESELLER
indexexchange.com,190966,RESELLER,50b1c356f2c5c8fc
indexexchange.com,191973,RESELLER,50b1c356f2c5c8fc
indexexchange.com,192410,RESELLER,50b1c356f2c5c8fc
lijit.com,231666-eb,RESELLER,fafdf38b16bf6b2b
lijit.com,231666,RESELLER,fafdf38b16bf6b2b
lijit.com,249474-eb,RESELLER,fafdf38b16bf6b2b
lijit.com,249474,RESELLER,fafdf38b16bf6b2b
lijit.com,260980-eb,RESELLER,fafdf38b16bf6b2b
lijit.com,260980,RESELLER,fafdf38b16bf6b2b
loopme.com,5679,RESELLER,6c8d5f95897a5a3b
openx.com,537120563,RESELLER,6a698e2ec38604c6
openx.com,537140323,RESELLER,6a698e2ec38604c6
openx.com,537143344,RESELLER,6a698e2ec38604c6
openx.com,537149888,RESELLER,6a698e2ec38604c6
openx.com,538959099,RESELLER,6a698e2ec38604c6
openx.com,539392223,RESELLER,6a698e2ec38604c6
openx.com,539924617,RESELLER,6a698e2ec38604c6
openx.com,540031703,RESELLER,6a698e2ec38604c6
openx.com,540191398,RESELLER,6a698e2ec38604c6
pubmatic.com,137711,RESELLER,5d62403b186f2ace
pubmatic.com,154037,RESELLER,5d62403b186f2ace
pubmatic.com,156077,RESELLER,5d62403b186f2ace
pubmatic.com,156078,RESELLER,5d62403b186f2ace
pubmatic.com,156212,RESELLER,5d62403b186f2ace
pubmatic.com,156423,RESELLER,5d62403b186f2ace
pubmatic.com,156439,RESELLER,5d62403b186f2ace
pubmatic.com,156612,RESELLER,5d62403b186f2ace
pubmatic.com,156700,RESELLER,5d62403b186f2ace
pubmatic.com,157150,RESELLER,5d62403b186f2ace
pubmatic.com,158100,RESELLER,5d62403b186f2ace
pubmatic.com,158569,RESELLER,5d62403b186f2ace
pubnative.net,1006576,RESELLER,d641df8625486a7b
rhythmone.com,1654642120,RESELLER,a670c89d4a324e47
rhythmone.com,2088589623,RESELLER,a670c89d4a324e47
rhythmone.com,2564526802,RESELLER,a670c89d4a324e47
rubiconproject.com,16114,RESELLER,0bfd66d529a55807
rubiconproject.com,16414,RESELLER,0bfd66d529a55807
rubiconproject.com,17174,RESELLER,0bfd66d529a55807
rubiconproject.com,17960,RESELLER,0bfd66d529a55807
rubiconproject.com,18020,RESELLER,0bfd66d529a55807
rubiconproject.com,21434,RESELLER,0bfd66d529a55807
rubiconproject.com,21642,RESELLER,0bfd66d529a55807
smaato.com,1100044045,RESELLER,07bcf65f187117b4
smartadserver.com,3357,RESELLER
sonobi.com,a416546bb7,RESELLER,d1a215d9eb5aee9e
sovrn.com,231666,RESELLER,fafdf38b16bf6b2b
sovrn.com,249474,RESELLER,fafdf38b16bf6b2b
sovrn.com,260980,RESELLER,fafdf38b16bf6b2b
video.unrulymedia.com,2088589623,RESELLER
xad.com,958,RESELLER,81cbf0a75a5e0e9a
yahoo.com,55029,RESELLER,e1a5b5b6e3255540
yahoo.com,56803,RESELLER,e1a5b5b6e3255540
yahoo.com,57289,RESELLER,e1a5b5b6e3255540
yahoo.com,57788,RESELLER,e1a5b5b6e3255540`;

export default ads_Txt;
