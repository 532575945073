export const DeleteIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_269_11183)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0C13.9698 0 18 4.03023 18 9C18 13.9698 13.9698 18 9 18C4.03023 18 0 13.9698 0 9C0 4.03023 4.03023 0 9 0ZM4.4511 12.2576L7.70875 9L4.4511 5.74235C4.26423 5.55541 4.26423 5.25047 4.4511 5.0636L5.0636 4.4511C5.25047 4.26423 5.55541 4.26423 5.74235 4.4511L9 7.70875L12.2576 4.4511C12.4446 4.26423 12.7495 4.26423 12.9364 4.4511L13.5489 5.0636C13.7358 5.25047 13.7358 5.55541 13.5489 5.74235L10.2913 9L13.5489 12.2576C13.7358 12.4446 13.7358 12.7495 13.5489 12.9364L12.9364 13.5489C12.7495 13.7358 12.4446 13.7358 12.2576 13.5489L9 10.2913L5.74235 13.5489C5.55541 13.7358 5.25047 13.7358 5.0636 13.5489L4.4511 12.9364C4.26423 12.7495 4.26423 12.4446 4.4511 12.2576Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_269_11183">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
