import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { Row } from "antd";
import PublisherSelect from "./PublisherSelect";
import { useSelector } from "react-redux";
import { AppState } from "@auth0/auth0-react";

const DomainSelection = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(true);
  const publisher = useSelector((state: AppState) => state.publisher);

  const navigate = useNavigate();

  const onClick = () => {
    navigate("/dashboard");
  };

  return (
    <Row className="wrapper" justify={"center"}>
      <div className="auto-complete">
        <div className="domain-header">
          <h1>{t("selection")}</h1>
          <h2 style={{ color: "#5F5F5F" }}>{t("selectDomain")}</h2>
        </div>

        <div style={{ width: "100%", display: "flex" }}>
          <PublisherSelect setDisabled={setDisabled} />
        </div>

        <div className="button">
          <Button
            onClick={onClick}
            title={t("continue") || ""}
            disabled={publisher && publisher.pub_name ? false : disabled}
            type="primary"
          />
        </div>
      </div>
    </Row>
  );
};

export default DomainSelection;
