import { Publisher } from "../../utils/types";
import client from "../client";
import { User } from "@auth0/auth0-react";

export const getPublishers = async () => {
  const res = await client.get(`/publishers`);
  return res.data;
};

export const getPublisherById = async (pub_id: number) => {
  const res = await client.get(`/publishers/${pub_id}`);
  return res.data;
};

export const insertPublisher = async (publisher: Publisher, user: User) => {
  const res = await client.post(`/publishers/insert`, {
    publisher: publisher,
    user: user,
  });
  return res.data;
};

export const updatePublisher = async (publisher: Publisher, user: User) => {
  const res = await client.post(`/publishers/update`, {
    publisher: publisher,
    user: user,
  });
  return res.data;
};

export const deletePublisher = async (pub_id: number) => {
  const res = await client.delete(`/publishers/delete/${pub_id}`);
  return res.data;
};

