import client from "../client";

export const getTags = async () => {
  const res = await client.get(`advertisers`);
  return res.data;
};

export const getSetups = async (tag: any) => {
  const res = await client.get(`advertisers/setups`, {
    params: tag,
  });
  return res.data;
};

export const updateTag = async (tag: any) => {
  const res = await client.post(`advertisers/update`, tag);
  return res.data;
};

export const insertTag = async (tag: any) => {
  const res = await client.post(`advertisers/insert`, tag);
  return res.data;
};

export const duplicateTag = async (tag: any) => {
  const res = await client.post(`advertisers/duplicate`, tag);
  return res.data;
};

export const setContentConfig = async (tag: any) => {
  const res = await client.post(`advertisers/set_content_config`, tag);
  return res.data;
};

export const getContentConfig = async (tag: any) => {
  const res = await client.post(`advertisers/get_content_config`, tag);
  return res.data;
};

export const deleteTag = async (tag: any) => {
  const res = await client.delete(`advertisers`, {
    data: tag,
  });
  return res.data;
};
