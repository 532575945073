import { useEffect, useState } from "react";
import { Col, Row, notification } from "antd";
import Button from "../../components/Button";
import Select from "../../components/Select/Select";
import { NotificationType } from "../../utils/notifictions";
import { getData, getLayout, updatePosts } from "../../api/services/Extension";
import { IDS } from "../../utils/elementsIds";
import SocialUnit from "./SocialUnit";

const ExtensionUpdate = () => {
  const [implementation, setImplementation] = useState<string>();
  const [layout, setLayout] = useState<number>();
  const [category, setCategory] = useState<string>();
  const [layoutsList, setLayoutsList] = useState<any[]>([]);
  const [json, setJson] = useState<any>();
  const [items, setItems] = useState<any[]>([]);
  const [change, setChange] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [api, contextHolder] = notification.useNotification();
  const implementationOptionsArr: any[] = [];
  let unitTypeOptionsArr: any[] = [];

  useEffect(() => {
    getLayoutsData();
  }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({ message: message });
  };

  const getLayoutsData = async () => {
    let layouts = await getData();
    setLayoutsList(layouts);
  };

  const getPosts = async () => {
    const selectedLayout = layoutsList.filter(
      (element) => element.layout === layout
    );
    const selectedURL = selectedLayout[0]?.urls[category || ""].replace(
      ".json",
      "_all.json"
    );

    setUrl("");
    setJson(null);
    setItems([]);

    if (selectedURL) {
      let data = {
        url: selectedURL,
      };
      const postList = await getLayout(data);
      setUrl(selectedURL);
      setJson(postList);
      setItems(postList.Items);
    }
    setChange(false);
  };

  const removePost = (index: number) => {
    if (items.length > 1) {
      const filteredItemsList = items.filter((element, idx) => idx !== index);
      setItems(filteredItemsList);
      setChange(true);
    } else {
      openNotificationWithIcon(
        "error",
        `you can't remove last item because unit will be empty`
      );
    }
  };

  const checkedPost = (index: number, val: boolean) => {
    const filteredItemsList = items.map((element, idx) => {
      if (idx === index) {
        element.Checked = val;
      }
      return element;
    });
    const checkedPosts =
      filteredItemsList.filter(
        (element) => !element.hasOwnProperty("Checked") || element.Checked
      ).length > 0;
    setItems(filteredItemsList);
    setChange(checkedPosts);
  };

  const changePost = (index: number, obj: any) => {
    const filteredItemsList = items.map((element, idx) => {
      return idx === index ? obj : element;
    });
    setItems(filteredItemsList);
    setChange(true);
  };

  const addPost = (index: number) => {
    const tempObj = [...items];
    tempObj.splice(index, 0, {
      Id: 100,
      Type: 0,
      ParentId: 0,
      IgnoreUserHistory: 0,
      ChartTitle: null,
      Title: null,
      Description: "",
      Link: null,
      Image: null,
      Answers: null,
      OnThisDayDate: null,
      Tweet: null,
      InstagramCode: "",
      TikTokCode: "",
      TwitterXCode: "",
      Checked: true,
    });
    setItems(tempObj);
  };

  const save = async () => {
    const finalJson = { ...json };
    finalJson.Items = items.filter((item) => item.Type > 0);
    finalJson.Items = items.map((item) =>
      !item.hasOwnProperty("Checked") ? { ...item, Checked: true } : item
    );
    const urlChecked = url.replace("_all.json", ".json");
    const finalJsonChecked = { ...finalJson };
    finalJsonChecked.Items = finalJson.Items.filter(
      (item: any) => item.Checked === true
    );
    if (finalJsonChecked.Items.length === 0) {
      openNotificationWithIcon("error", `at least one post must be checked`);
      return false;
    }

    const res = await updatePosts(url, finalJson);
    const resChecked = await updatePosts(urlChecked, finalJsonChecked);
    if (res && resChecked && res === "ok" && resChecked === "ok") {
      openNotificationWithIcon("success", "posts saved successfully");
    } else {
      openNotificationWithIcon("error", `there was an error`);
    }
  };

  layoutsList.forEach((element: any) => {
    if (!implementationOptionsArr.includes(element.implementation)) {
      implementationOptionsArr.push(element.implementation);
    }
  });

  if (implementation) {
    unitTypeOptionsArr = layoutsList.filter(
      (element) => element.implementation === implementation
    );
  }

  return (
    <Row className="unit-select">
      <Row justify="space-between" className="full-width">
        <Col span={24}>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
              gap: 24,
            }}
          >
            <Select
              id={IDS.EXTENSION_UPDATE.IMPLEMENTATION_SELECT}
              style={{ width: "20%" }}
              options={implementationOptionsArr.map((option: any) => {
                return { value: option, label: option };
              })}
              onChange={(value: string) => {
                setItems([]);
                setChange(false);
                setImplementation(value);
              }}
              placeholder="Select Implementation"
            />

            <Select
              id={IDS.EXTENSION_UPDATE.UNITTYPE_SELECT}
              style={{ width: "20%" }}
              options={unitTypeOptionsArr.map((option: any) => {
                return { value: option.layout, label: option.unit };
              })}
              onChange={(value) => {
                setItems([]);
                setChange(false);
                setLayout(value);
              }}
              placeholder="Select Unit Type"
            />

            <Select
              id={IDS.EXTENSION_UPDATE.CATEGORYTYPE_SELECT}
              style={{ width: "20%" }}
              options={[
                { value: "news", label: "News" },
                { value: "sport", label: "Sport" },
                { value: "food", label: "Food" },
                { value: "health", label: "Health" },
                { value: "education", label: "Education" },
                { value: "entertainment", label: "Entertainment" },
              ]}
              onChange={(value) => {
                setItems([]);
                setChange(false);
                setCategory(value);
              }}
              placeholder="Select Category Type"
            />

            <Button
              type="primary"
              id={IDS.EXTENSION_UPDATE.PREVIEW_BUTTON}
              title="Preview"
              disabled={!implementation || !layout || !category}
              onClick={getPosts}
            />
            <Button
              permission="update:extensionUpdate"
              type="primary"
              id={IDS.EXTENSION_UPDATE.SAVE_BUTTON}
              title="Save"
              disabled={!change}
              onClick={save}
            />
          </Row>

          <Col>
            <Row className="social-items-container">
              {items.map((item, index) => {
                return (
                  <SocialUnit
                    key={index}
                    index={index}
                    item={item}
                    removePost={removePost}
                    changePost={changePost}
                    addPost={addPost}
                    checkedPost={checkedPost}
                  />
                );
              })}
            </Row>
          </Col>
        </Col>
      </Row>
      {contextHolder}
    </Row>
  );
};

export default ExtensionUpdate;
