import client from "../client";

export const getData = async () => {
  const res = await client.get(`extension`);
  return res.data;
};

export const getLayout = async (data: any) => {
  const res = await client.get(`extension/layout`, {
    params: data,
  });
  return res.data;
};

export const updatePosts = async (url: string, data: any) => {
  const res = await client.post(`extension/update_posts`, { url: url, data: data });
  return res.data;
};



