import { useState } from "react";
import Button from "../../components/Button";
import { Alert, Row, Table } from "antd";
import { Input } from "antd";
import { searchMultiLines } from "../../api/services/Domains";
import {
  SearchOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";
import PureCard from "../../components/PureCard/PureCard";
import "./styles.scss";
import TextArea from "antd/es/input/TextArea";
import * as XLSX from "xlsx";

const AdsTxtMultiLine = () => {
  const [url, setUrl] = useState<string>("");
  const [lines, setLines] = useState<string>("");
  const [showTable, setShowTable] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [data, setData] = useState<any[]>([]);

  const getColumns = () => {
    return [
      {
        title: "Line",
        key: "line",
        dataIndex: "line",
      },
      {
        title: "Line exist",
        key: "exist",
        dataIndex: "exist",
      },
      {
        title: "Count",
        key: "count",
        dataIndex: "count",
      },
    ];
  };

  const getDataSource = () => {
    let tmp: any[] = [];
    for (let index = 0; index < data.length; index++) {
      let item = data[index];
      tmp.push({
        key: index.toString(),
        line: item.line,
        exist: (
          <Row className="icon">
            {item.isExsist ? (
              <CheckSquareOutlined style={{ color: "#73d13d" }} />
            ) : (
              <CloseSquareOutlined style={{ color: "red" }} />
            )}
          </Row>
        ),
        count: item.count,
      });
    }
    return tmp;
  };

  const getDataForExport = () => {
    let tmp: any[] = [];
    for (let index = 0; index < data.length; index++) {
      let item = data[index];
      tmp.push({
        line: item.fullLine,
        exist: item.isExsist ? "Yes" : "No",
        count: item.count,
      });
    }
    return tmp;
  };

  const compare = async () => {
    setValidationMessage("");
    if (!url.endsWith("/ads.txt")) {
      setValidationMessage(
        "Url must end with /ads.txt like example: https://www.example.com/ads.txt "
      );
    } else if (!url.startsWith("https://")) {
      setValidationMessage(
        "Url must start with https:// like example: https://www.example.com/ads.txt "
      );
    } else {
      let array = lines.split("\n");
      setShowTable(true);
      let data = await searchMultiLines(url, array);
      setData(data);
    }
  };

  const exportXlsx = () => {
    const worksheet = XLSX.utils.json_to_sheet(getDataForExport());
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    let fileName = `txt_multi_lines_${new Date().toISOString()}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <div className="ads-txt-multi-container">
      <Row className="title">
        <h2> Welcome to multi lines search!</h2>
      </Row>

      {!showTable && (
        <>
          <Row>
            <Input
              placeholder="https://www.example.com/ads.txt"
              onChange={(e) => setUrl(e.target.value)}
              size="large"
              value={url}
            />
          </Row>
          <Row>
            <TextArea
              spellCheck={false}
              value={lines}
              onChange={(e) => setLines(e.target.value)}
              placeholder="Paste your lines here"
              className="area"
            />
          </Row>
          {validationMessage !== "" && (
            <Row>
              <Alert
                message={validationMessage}
                type="error"
                showIcon
                style={{ width: "100%" }}
              />
            </Row>
          )}
          <Button
            title="Compare"
            type="primary"
            size="large"
            onClick={compare}
            icon={<SearchOutlined />}
            disabled={url === "" || lines.trim() === ""}
          ></Button>
        </>
      )}

      {showTable && (
        <>
          <PureCard isLoading={data.length === 0}>
            <Button
              title="Download XLSX"
              type="primary"
              size="large"
              onClick={() => {
                exportXlsx();
              }}
            ></Button>
            <Table
              dataSource={getDataSource()}
              columns={getColumns()}
              style={{ marginTop: "24px" }}
            />
          </PureCard>
          <Row className="back-button">
            <Button
              title="Back to search"
              type="primary"
              size="large"
              onClick={() => {
                setData([]);
                setShowTable(false);
              }}
              icon={<SearchOutlined />}
              disabled={url === "" || lines === ""}
            ></Button>
          </Row>
        </>
      )}
    </div>
  );
};

export default AdsTxtMultiLine;
