import React, { useEffect, useState, useCallback } from "react";
import { Row, Table, DatePicker, InputNumber, Form, notification } from "antd";
import Button from "../../components/Button";
import { SaveOutlined } from '@ant-design/icons';
import PureCard from "../../components/PureCard/PureCard";
import dayjs from "dayjs";
import { NotificationType } from "../../utils/notifictions";
import { getAdjustments, saveAdjustment, exportAdjustment } from "../../api/services/Adjustments";
import "./styles.scss";

interface DataType {
  id: number;
  domain_id: string;
  desktop_revenue: number;
  mobile_revenue: number;
  pwl_desktop: number;
  pwl_mobile: number;
  adjusted_desktop_revenue?: number;
  adjusted_mobile_revenue?: number;
  adjusted_pwl_desktop?: number;
  adjusted_pwl_mobile?: number;
}

const EditableCell: React.FC<{
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataType;
  record: DataType;
  handleSave: (record: DataType, values: any) => void;
}> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [form] = Form.useForm();
  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave(record, values);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    const def = JSON.stringify(children),
          arr = JSON.parse(def),
          initialValues = {
            [dataIndex]: arr[1] || ''
          };

    childNode = <Form form={form} component={false} initialValues={initialValues}>
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          <InputNumber onBlur={save} formatter={(value) => thousands(value)} />
        </Form.Item>
      </Form>;
  }

  return <td {...restProps}>{childNode}</td>;
};


const thousands = (num: any) => {
  return Number(num).toLocaleString();
}

const Adjustments = () => {
  const [originalDate, setOriginalDate] = useState<any>();
  const [dataLog, setDataLog] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const getData = useCallback(async (date: any) => {
    setDataLog([]);
    setIsLoading(true);
    let response = await getAdjustments(date);

    response = response.map((element: any) => {
      if(element.domain_id === 2845 && !element.adjusted_pwl_desktop){
        element.adjusted_pwl_desktop = element.desktop_widget_loads - (element.desktop_widget_loads * 0.30);
      }
      if(element.domain_id === 2846 && !element.adjusted_pwl_desktop){
        element.adjusted_pwl_desktop = element.desktop_widget_loads - (element.desktop_widget_loads * 0.50);
      }
      element.desktop_revenue = thousands(element.desktop_revenue);
      element.mobile_revenue = thousands(element.mobile_revenue);
      element.desktop_widget_loads = thousands(element.desktop_widget_loads);
      element.mobile_widget_loads = thousands(element.mobile_widget_loads);
      element.pwl_desktop = thousands(element.pwl_desktop);
      element.pwl_mobile = thousands(element.pwl_mobile);
      return element;
    });
    
    setDataLog(response);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (originalDate) getData(originalDate);
  }, [originalDate, getData]);

  const handleSave = (row: any, val: any) => {
    let newData = [...dataLog];
    const item = { ...newData[row], ...val };
    newData[row] = item;
    setDataLog(newData);
  };

  const getColumns = () => {
    return [
      {
        title: "Domain",
        key: "domain_id",
        dataIndex: "domain_name",
        width: "9%",
      },
      {
        title: "Revenue desktop",
        dataIndex: "desktop_revenue",
        width: "9%",
      },
      {
        title: "Revenue mobile",
        dataIndex: "mobile_revenue",
        width: "9%",
      },
      {
        title: "Desktop widget loads",
        dataIndex: "desktop_widget_loads",
        width: "9%",
      },
      {
        title: "Mobile widget loads",
        dataIndex: "mobile_widget_loads",
        width: "9%",
      },
      {
        title: "PWL desktop",
        dataIndex: "pwl_desktop",
        width: "9%",
      },
      {
        title: "PWL mobile",
        dataIndex: "pwl_mobile",
        width: "9%",
      },
      {
        title: "Adjusted desktop revenue",
        dataIndex: "adjusted_desktop_revenue",
        editable: true,
        width: "9%",
      },
      {
        title: "Adjusted mobile revenue",
        dataIndex: "adjusted_mobile_revenue",
        editable: true,
        width: "9%",
      },
      {
        title: "Adjusted PWL desktop",
        dataIndex: "adjusted_pwl_desktop",
        editable: true,
        width: "9%",
      },
      {
        title: "Adjusted PWL mobile",
        dataIndex: "adjusted_pwl_mobile",
        editable: true,
        width: "9%",
      }
    ];
  };
  
  const onChangeDate = (date: any) => {
    date = date ? dayjs(date).format('YYYY-MM-DD') : dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    setOriginalDate(date);
  };

  const disabledDate = (current: any) => {
    return current && current >= dayjs().startOf('day');
  };

  const mergedColumns = getColumns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (_: any,record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className="adjustments">
      <PureCard>
        <Row style={{ marginBottom: 24 }}>
          <div className="container">
            <div className="center-container">
            <DatePicker size="large" onChange={onChangeDate} format="DD/MM/YYYY" disabledDate={disabledDate} allowClear={false} />
            </div>
            <Button
              type="primary"
              size="large"
              title="Save"
              icon={<SaveOutlined />}
              onClick={() => {
                exportAdjustment(originalDate, dataLog);
                saveAdjustment(originalDate, dataLog);
                openNotificationWithIcon("success", "Adjustment added successfully");
              }}
            ></Button>
          </div>
        </Row>

        {dataLog.length > 0 && (
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            columns={mergedColumns}
            dataSource={dataLog}
            loading={isLoading}
            rowKey={"domain_id"}
            pagination={false}
            scroll={{ x: 1000 }}
          />
        )}

        {!dataLog.length && (
          <Table
            bordered
            columns={mergedColumns}
            loading={isLoading}
            rowKey="domain_id"
            pagination={false}
          />
        )}

        {contextHolder}
      </PureCard>
    </div>
  );
};

export default Adjustments;