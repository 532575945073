import { Spin } from "antd";
import "./styles.scss";

const Loading = () => {
  return (
    <>
      <div id="myModal" className="modal active">
        <Spin size="large" className="spin" />
      </div>
    </>
  );
};

export default Loading;
