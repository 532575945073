export const EditIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_269_11199)">
        <path
          d="M14.2504 8.99971C13.8357 8.99971 13.5004 9.33576 13.5004 9.74968V15.7499C13.5004 16.1632 13.1644 16.4999 12.7505 16.4999H2.25007C1.83601 16.4999 1.50009 16.1632 1.50009 15.7499V5.24954C1.50009 4.8363 1.83601 4.49956 2.25007 4.49956H8.25031C8.66506 4.49956 9.00029 4.16351 9.00029 3.74958C9.00029 3.33552 8.66506 2.99947 8.25031 2.99947H2.25007C1.00954 2.99947 0 4.009 0 5.24954V15.7499C0 16.9905 1.00954 18 2.25007 18H12.7505C13.991 18 15.0005 16.9905 15.0005 15.7499V9.74968C15.0005 9.33494 14.6652 8.99971 14.2504 8.99971Z"
          fill={color}
        />
        <path
          d="M7.03212 8.31648C6.97965 8.36894 6.94436 8.43568 6.92939 8.50765L6.39915 11.1598C6.37443 11.2827 6.41343 11.4095 6.50187 11.4988C6.57315 11.57 6.66914 11.6082 6.76747 11.6082C6.79137 11.6082 6.81623 11.606 6.84095 11.6008L9.49231 11.0706C9.56578 11.0555 9.63253 11.0203 9.6843 10.9677L15.6185 5.03352L12.9671 2.38229L7.03212 8.31648Z"
          fill={color}
        />
        <path
          d="M17.4515 0.548476C16.7204 -0.182825 15.5308 -0.182825 14.8002 0.548476L13.7622 1.58644L16.4136 4.2378L17.4515 3.1997C17.8056 2.84648 18.0006 2.37542 18.0006 1.87443C18.0006 1.37344 17.8056 0.902384 17.4515 0.548476Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_269_11199">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
