import Modal from "../components/Modal/Modal";
import { Checkbox, Form, InputNumber, Row } from "antd";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import "./styles.scss";
import { IDS } from "../utils/elementsIds";
import { LoadingOutlined } from "@ant-design/icons";
import { getContentConfig } from "../api/services/Advertisers";
import { defaultTimer } from "../utils/utils";

interface AdSetupModalProps {
  close: () => void;
  onFinish: (tag: any) => void;
}

const AdContentSetupModal = (props: AdSetupModalProps) => {
  const { close, onFinish } = props;
  const [publisher, setPublisher] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [pubKey, setPubKey] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [wid, setWid] = useState<number | undefined>(undefined);
  const [timer, setTimer] = useState<number>(5);

  useEffect(() => {
    getData();
    setDisabled(false);
  }, [wid, publisher, domain]);

  const getData = async () => {
    if (publisher !== "" && domain !== "" && wid) {
      setIsLoading(true);

      let res = await getContentConfig({
        pub_id: publisher,
        domain_id: domain,
        wid,
      });
      if (res.length > 0) setTimer(res[0].timer);
      else setTimer(5);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={"40%"}
      title={<h2>Content Spot Configs</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
      className="ad-setup-modal"
    >
      <Form
        name="basic"
        onFinish={() => {
          onFinish({
            pub_id: publisher,
            pub_key: pubKey,
            domain_id: domain,
            wid,
            timer: timer,
          });
        }}
        autoComplete="off"
        layout="vertical"
      >
        <Row justify="space-between">
          <Form.Item
            label="Choose Publisher"
            name="publisher"
            rules={[
              {
                required: publisher === "",
                message: "Please select publisher",
              },
            ]}
            className="form-item"
          >
            <PublisherSelect
              id={IDS.ADVERTISERS.CONFIGS_PUBLISHER_SELECT}
              mode="new"
              onSelect={(pub_id, pub_key) => {
                setPublisher(pub_id);
                setPubKey(pub_key);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Choose Domain"
            name="domain"
            rules={[
              {
                required: domain === "",
                message: "Please select domain",
              },
            ]}
            className="form-item"
          >
            <DomainSelect
              id={IDS.ADVERTISERS.CONFIGS_DOMAIN_SELECT}
              onSelect={(value: any) => {
                setDomain(value);
              }}
              disabled={publisher === ""}
            />
          </Form.Item>
        </Row>

        <Row justify="space-between">
          <Form.Item
            label="Choose WID"
            name="wid"
            rules={[{ required: true, message: "Please select WID" }]}
            className="form-item"
          >
            <InputNumber
              id={IDS.ADVERTISERS.CONFIGS_WID_INPUT}
              placeholder="Please select WID"
              size="large"
              className="full-width"
              type="number"
              onChange={(value: any) => setWid(value)}
            />
          </Form.Item>

          {!disabled && (
            <Form.Item
              label="Choose Timer (in seconds)"
              rules={[{ required: true, message: "Please select timer" }]}
              style={{ width: "30%" }}
            >
              <InputNumber
                {...(isLoading && { addonBefore: <LoadingOutlined /> })}
                id={IDS.ADVERTISERS.CONFIGS_TIMER_INPUT}
                value={timer}
                disabled={
                  publisher === "" || domain === "" || (!wid && wid !== 0)
                }
                placeholder="Please select timer"
                size="large"
                className="full-width"
                type="number"
                onChange={(value) => setTimer(value || 0)}
              />
            </Form.Item>
          )}

          <Checkbox
            style={{ marginTop: "auto", marginBottom: "auto" }}
            onChange={async (e) => {
              setDisabled(e.target.checked);
              if (e.target.checked) setTimer(defaultTimer);
              else await getData();
            }}
            checked={disabled}
          >
            Disabled
          </Checkbox>
        </Row>

        <Row className="buttons">
          <Button type="default" title="Cancel" onClick={close}></Button>
          <Button
            id={IDS.ADVERTISERS.CONFIGS_SAVE_BUTTON}
            type="primary"
            title={"Save Configs"}
            htmlType="submit"
          ></Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AdContentSetupModal;
