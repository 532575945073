import client from "../client";

export const getAdjustments = async (date: any) => {
  const res = await client.get("/adjustments", {
    params: { date },
  });
  return res.data;
};

export const saveAdjustment = async (date: string, data: any) => {
  const res = await client.post("/adjustments/update", { date: date, json: data });
  return res.data;
};

export const exportAdjustment = async (date: string, data: any) => {
  const res = await client.post("/adjustments/export", { date: date, json: data });
  return res.data;
};