const CONFIG = require("./config.json");

export const sendGtmEvent = (eventCategory, user) => {
  console.log(">>>>>>>>>>gtm event send");

  if (user) {
    let roles = user["dev-ovcxuhr3lpxgrden.us.auth0.com/roles"];
    let env = CONFIG.env;

    if (!roles.includes("admin") && env === "PROD") {
      window.dataLayer.push({
        event: "ga_event",
        eventCategory: eventCategory,
        eventAction: "User Click",
        userId: user.sub,
      });
    }
  }
};
