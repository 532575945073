import { Test } from "../../utils/types";
import client from "../client";

export const getABtests = async () => {
  const res = await client.get("/abtests");
  return res.data;
};

export const getTest = async (test: Test) => {
  const res = await client.post("/abtests/find", test);
  return res.data;
};

export const insertTest = async (test: Test) => {
  const res = await client.post(`abtests/insert`, test);
  return res.data;
};

export const updateTest = async (test: Test) => {
  const res = await client.post(`abtests/update`, test);
  return res.data;
};
