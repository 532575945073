import { useAuth0 } from "@auth0/auth0-react";
import Modal from "../components/Modal/Modal";
import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/store";

const NoPermissionModal = () => {
  const { permissionModalData } = useSelector((state: AppState) => state);
  const dispatch = useDispatch();

  return (
    <Modal
      width={"50%"}
      title={<h1>No Permission</h1>}
      isModalOpen={true}
      setIsModalOpen={() => {}}
      okText={"CLOSE"}
      onOk={() => {
        dispatch({
          type: "SET_SHOW_NO_PERMISSION_MODAL",
          payload: { show: false, message: "" },
        });
      }}
      hideCncelButton
      removeCloseButton={true}
    >
      <Row>
        <h2>{permissionModalData.message}.</h2>
      </Row>
    </Modal>
  );
};

export default NoPermissionModal;
