import { ProviderDetailes } from "../../utils/types";
import client from "../client";

export const getAll = async () => {
  const res = await client.get(`advertisers`);
  return res.data;
};

export const getWithAggregator = async () => {
  const res = await client.get(`providers/data`);
  return res.data;
};

export const deleteProvider = async (id: number) => {
  const res = await client.delete(`providers/${id}`);
  return res.data;
};

export const addProvider = async (provider: ProviderDetailes) => {
  const res = await client.post(`providers/insert`, { provider });
  return res.data;
};

export const updateProvider = async (provider: ProviderDetailes) => {
  const res = await client.post(`providers/update`, { provider });
  return res.data;
};
