import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Checkbox, Row, Table } from "antd";
import { Input } from "antd";
import {
  searchSingleLines,
  getActiveDomainsUrls,
} from "../../api/services/Domains";
import Select from "../../components/Select/Select";
import {
  SearchOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";
import PureCard from "../../components/PureCard/PureCard";
import "./styles.scss";
const AdsTxtSingelLine = () => {
  const [options, setOptions] = useState<any[]>([]);
  const [mapOptions, setMapOptions] = useState<any>({});
  const [selectedItems, setSelectedItems] = useState<string[]>(["all"]);
  const [line, setLine] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    getallDomainesUrl();
  }, []);

  const getallDomainesUrl = async () => {
    let response = await getActiveDomainsUrls();
    let tmpOptions = [{ label: "all", value: "all" }];
    let tmpMapOptions: any = {};
    for (let i = 0; i < response.length; i++) {
      tmpMapOptions[response[i].name] = response[i].url;
      tmpOptions.push({
        label: response[i].name,
        value: response[i].name,
      });
    }
    setOptions(tmpOptions);
    setMapOptions(tmpMapOptions);
  };

  const searchOnAdsTxt = async () => {
    setIsLoading(true);
    let urls = [];

    if (selectedItems.includes("all")) {
      for (let item of options) {
        let url = mapOptions[item.value];
        if (item.value != "all") urls.push(url);
      }
    } else {
      for (let id of selectedItems) {
        let url = mapOptions[id];
        urls.push(url);
      }
    }
    let isExsistArray = await searchSingleLines(urls, line);
    setIsLoading(false);
    setData(isExsistArray);
  };

  const getColumns = () => {
    return [
      {
        title: "Domain",
        key: "domain",
        dataIndex: "domain",
      },
      {
        title: "Line exist",
        key: "line",
        dataIndex: "line",
      },
      {
        title: "file exist",
        key: "file",
        dataIndex: "file",
      },
    ];
  };

  const getDataSource = () => {
    let tmp: any[] = [];
    for (let index = 0; index < data.length; index++) {
      let item = data[index];
      if (showAll || (!showAll && !item.isExsist))
        tmp.push({
          key: index.toString(),
          domain: item.url,
          line: (
            <Row
              style={{
                fontSize: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {item.isExsist ? (
                <CheckSquareOutlined style={{ color: "#73d13d" }} />
              ) : (
                <CloseSquareOutlined style={{ color: "red" }} />
              )}
            </Row>
          ),
          file: (
            <Row
              style={{
                fontSize: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {item.isFileExsist ? (
                <CheckSquareOutlined style={{ color: "#73d13d" }} />
              ) : (
                <CloseSquareOutlined style={{ color: "red" }} />
              )}
            </Row>
          ),
        });
    }
    return tmp;
  };

  const onSelect = (value: any) => {
    if (value == "all") {
      setSelectedItems(["all"]);
    } else {
      let tmp = [...selectedItems];
      if (value != "all" && tmp.includes("all"))
        tmp.splice(tmp.indexOf("all"), 1);
      tmp.push(value);
      setSelectedItems(tmp);
    }
  };

  const onDeselect = (value: any) => {
    let tmp = [...selectedItems];
    tmp.splice(tmp.indexOf(value), 1);

    if (tmp.length == 0) tmp.push("all");
    setSelectedItems(tmp);
  };

  return options.length > 0 ? (
    <div className="ads-txt-line-container">
      <Row
        style={{
          width: "100%",
          height: 30,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h2> Welcome to single line search!</h2>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: "40%" }}
          placeholder="Please select domain"
          value={selectedItems}
          onSelect={onSelect}
          onDeselect={onDeselect}
          onClear={() => {
            setSelectedItems(["all"]);
          }}
          options={options}
        />

        <Input
          style={{ width: "40%" }}
          placeholder="Type here ads txt line"
          onChange={(e) => setLine(e.target.value)}
        />

        <Button
          title="Search"
          type="primary"
          size="large"
          onClick={() => {
            setShowTable(true);
            searchOnAdsTxt();
            setShowAll(false);
          }}
          icon={<SearchOutlined />}
          disabled={line == ""}
        ></Button>
      </Row>

      {showTable && (
        <PureCard isLoading={isLoading}>
          <Checkbox
            style={{ marginBottom: 24 }}
            onChange={() => setShowAll(!showAll)}
            value={showAll}
          >
            Show All
          </Checkbox>

          <Table dataSource={getDataSource()} columns={getColumns()} />
        </PureCard>
      )}
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
      }}
    ></div>
  );
};

export default AdsTxtSingelLine;
