import { Select as SelectAntd, Tag } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { CSSProperties, MouseEventHandler } from "react";

interface SelectProps {
  options: DefaultOptionType[];
  onChange?: (value: any, option: any) => void;
  onSelect?: (value: any) => void;
  onDeselect?: (value: any) => void;
  onClear?: () => void;
  onSearch?: (value: any) => void;
  suffixIcon?: React.ReactNode;
  disabled?: boolean;
  defaultValue?: string | string[];
  mode?: "multiple" | "tags" | undefined;
  allowClear?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  value?: string | string[];
  showSearch?: boolean;
  optionFilterProp?: string;
  filterOption?: any;
  filterSort?: (
    optionA: DefaultOptionType,
    optionB: DefaultOptionType
  ) => number;
  showArrow?: boolean;
  tagRender?: any;
  maxTagCount?: number;
  dropdownStyle?: CSSProperties;
  onInputKeyDown?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  open?: false;
  id?: string;
  disabledVirtual?: boolean;
}

const Select = (props: SelectProps) => {
  const {
    options,
    onChange,
    onSelect,
    suffixIcon,
    defaultValue,
    disabled,
    mode,
    allowClear,
    placeholder,
    style,
    value,
    onDeselect,
    onClear,
    onSearch,
    showSearch,
    optionFilterProp,
    filterOption,
    filterSort,
    showArrow,
    tagRender,
    maxTagCount,
    dropdownStyle,
    onInputKeyDown,
    className,
    open,
    id,
    disabledVirtual,
  } = props;

  return (
    <SelectAntd
      mode={mode || undefined}
      disabled={disabled}
      defaultValue={defaultValue}
      style={style || { width: "100%" }}
      onChange={onChange}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onSearch={onSearch}
      onClear={onClear}
      options={options}
      size="large"
      suffixIcon={suffixIcon}
      allowClear={allowClear || false}
      placeholder={placeholder}
      value={value}
      showSearch={showSearch}
      {...(optionFilterProp && { optionFilterProp })}
      {...(filterOption && { filterOption })}
      {...(filterSort && { filterSort })}
      {...(tagRender && { tagRender })}
      {...(maxTagCount && { maxTagCount })}
      {...(id && { id })}
      {...(dropdownStyle && { dropdownStyle })}
      {...(onInputKeyDown && { onInputKeyDown })}
      {...(className && { className: className })}
      {...(disabledVirtual && { virtual: false })}
      showArrow={showArrow}
      open={open}
    />
  );
};
export default Select;
