import React, { useRef, useState } from "react";
import { Modal as ModalAntD } from "antd";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
interface ModalProps {
  children: React.ReactNode;
  title: React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: (flag: boolean) => void;
  width?: number | string;
  height?: number | string;
  hideOkButton?: boolean;
  hideCncelButton?: boolean;
  okText?: React.ReactNode;
  okDisabled?: boolean;
  onOk?: () => void;
  className?: string;
  removeCloseButton?: boolean;
}

const Modal = (props: ModalProps) => {
  const {
    children,
    title,
    isModalOpen,
    setIsModalOpen,
    width,
    hideOkButton,
    hideCncelButton,
    okText,
    okDisabled,
    onOk,
    height,
    className,
    removeCloseButton,
  } = props;
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const handleOk = () => {
    onOk && onOk();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <ModalAntD
        width={width ? width : "40%"}
        style={{ cursor: "move" }}
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        okText={okText}
        onCancel={handleCancel}
        {...(hideCncelButton && {
          cancelButtonProps: { style: { display: "none" } },
        })}
        {...(hideOkButton && {
          okButtonProps: { style: { display: "none" } },
        })}
        {...(okDisabled && {
          okButtonProps: { disabled: true },
        })}
        {...(className && { className: className })}
        {...(removeCloseButton && { closable: false })}
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "100vh",
          marginBottom: "auto",
          marginTop: "auto",
        }}
      >
        {children}
      </ModalAntD>
    </>
  );
};

export default Modal;
