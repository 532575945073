import React from "react";
import { Skeleton } from "antd";
import "./styles.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";

interface CardProps {
  title?: string;
  description?: any;
  onEmptySearch?: () => void;
  isLoading?: boolean;
  desktop?: any;
  mobile?: any;
}

const IntuitiveCard = (props: CardProps) => {
  let { title, description, isLoading, desktop, mobile } = props;
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  if(description) description = description.toString().includes("NaN") ? "0" : description;
  if(mobile) mobile = mobile.toString().includes("NaN") ? "0" : mobile;
  if(desktop) desktop = desktop.toString().includes("NaN") ? "0" : desktop;

  return (
    <div className={`intuitive-card ${ isDarkMode ? "intuitive-dark-card" : "" }`}>
      <div className="intuitive-title">{title}</div>
      {isLoading ? (
        <Skeleton loading={true} avatar active></Skeleton>
      ) : (
        <div className="intuitive-content">
          { description && <div className="intuitive-total">{ description } <span className="grey">Total</span></div> }
          { (description.trim() !== "0" && description.trim() !== "$0" && description.trim() !== "$0.00" && (mobile || desktop)) ?
            <div className="intuitive-details">
              { mobile && <span><span className="grey">Mobile</span> { mobile || "" }</span> }
              { desktop && <span><span className="grey">Desktop</span> { desktop || "" }</span> }
            </div> : null
          }
        </div>
      )}
    </div>
  );
};
export default IntuitiveCard;
