import AppRoutes from "./components/routes/routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "./store/store";
import "./index.scss";
import { useCallback, useEffect } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
const queryClient = new QueryClient();
const { defaultAlgorithm, darkAlgorithm } = theme;

const App = () => {
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const setRoles = useCallback((user: User) => {
    let roles = user["dev-ovcxuhr3lpxgrden.us.auth0.com/roles"];
    if (roles && roles.length > 0) {
      dispatch({ type: "SET_ROLES", payload: roles });
    }
  }, []);

  const setUser = useCallback((user: User) => {
    dispatch({ type: "SET_USER", payload: user });
  }, []);

  useEffect(() => {
    if (user) {
      setUser(user);
      setRoles(user);
    }
  }, [user]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: isDarkMode ? "#FDB813" : "#4097FF",
          colorText: isDarkMode ? "white" : "#262626",
          fontFamily: "Nunito",
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
