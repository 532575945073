import { useEffect, useState } from "react";
import Button from "../../components/Button";
import {
  Col,
  Divider,
  Input,
  Row,
  Image,
  notification,
  Select as AntSelect,
  Tag,
  Spin,
} from "antd";
import "./styles.scss";
import Select from "../../components/Select/Select";
import { getDomainsByPubId } from "../../api/services/Domains";
import InstegramUnit from "./InstegramUnit";
import selectMessage from "../../assets/images/selectMessage.svg";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { useAuth0 } from "@auth0/auth0-react";
import { getSocialData, updatePosts } from "../../api/services/Unit";
import { NotificationType } from "../../utils/notifictions";
import AliceCarousel from "react-alice-carousel";
import { SearchOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import "react-alice-carousel/lib/alice-carousel.css";
import PublisherSelect from "../DomainSelection/PublisherSelect";
import PureCard from "../../components/PureCard/PureCard";
import { sendGtmEvent } from "../../gtm";
import instagramIcon from "../../assets/images/instagram.svg";
import { GridIcon } from "../../assets/images/grid";
import { CarouselIcon } from "../../assets/images/carousel";

import tiktokIcon from "../../assets/images/tiktok.svg";
import twittterIcon from "../../assets/images/twitter.svg";
import { COLORS } from "../../utils/colors";
import TiktokUnit from "./TiktokUnit";
import { capitalizeFirstLetter, checkPermissionOk } from "../../utils/utils";
import DomainSelect from "../DomainSelection/DomainSelect";
import TwitterUnit from "./TwitterUnit";
import { IDS } from "../../utils/elementsIds";
const { Option } = AntSelect;

type Stages = "discover" | "result" | "carousel" | "loading" | "onSave";
type Platforms = "instagram" | "tiktok" | "twitter" | string;
type Method = "entity" | "hashtag" | undefined;
const SocialUnitSelect = () => {
  const [options, setOptions] = useState<any[]>([]);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [filters, setFilters] = useState<Platforms[]>([
    "instagram",
    "tiktok",
    "twitter",
  ]);
  const [data, setData] = useState<any>(undefined);
  const [mode, setMode] = useState<"carousel" | "grid">("grid");
  const [method, setMethod] = useState<Method>(undefined);
  const [entity, setEntity] = useState<string>("");

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const publisher = useSelector((state: AppState) => state.publisher);
  const { permissions } = useSelector((state: AppState) => state);
  const [api, contextHolder] = notification.useNotification();
  const [disableShowButton, setDisableShowButton] = useState<boolean>(false);
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const [stage, setStage] = useState<Stages>("discover");
  const { user } = useAuth0();

  const responsive = {
    0: {
      items: 1,
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };

  useEffect(() => {
    getallDomainesUrl();
  }, []);

  useEffect(() => {
    getallDomainesUrl();
  }, [publisher]);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const onUnitClicked = (code: string, platform: string, hashtag: string) => {
    let tmp = [...selectedItems];
    let index = tmp.findIndex(
      (item: any) => item.code === code && item.platform === platform
    );
    if (index === -1) {
      let obj = { code, platform, hashtag };
      tmp.push(obj);
    } else {
      tmp.splice(index, 1);
    }
    setSelectedItems(tmp);
  };

  const getallDomainesUrl = async () => {
    let response = await getDomainsByPubId(getPubId());
    let tmpOptions = [];
    for (let i = 0; i < response?.length; i++) {
      tmpOptions.push({
        label: response[i].name,
        value: response[i].domain_id,
      });
    }
    setOptions(tmpOptions);
  };

  const getPubId = () => {
    if (user) {
      if (!(permissions && checkPermissionOk("read:domains", permissions))) {
        let { pub_id } =
          user["dev-ovcxuhr3lpxgrden.us.auth0.com/user_metadata"];
        return pub_id;
      } else {
        return publisher?.key;
      }
    } else return 0;
  };

  const countByPlatform = () => {
    let count: any = {};
    for (let item of selectedItems) {
      if (count[item.platform]) {
        count[item.platform]++;
      } else {
        count[item.platform] = 1;
      }
    }
    return count;
  };

  const save = async () => {
    let countObj = countByPlatform();
    let keysWithValueGreaterThan4 = Object.keys(countObj).filter(
      (key) => countObj[key] < 4
    );
    if (keysWithValueGreaterThan4.length > 0) {
      openNotificationWithIcon(
        "error",
        `please select at least 4 posts from platforms ${JSON.stringify(
          keysWithValueGreaterThan4
        )}`
      );
    } else {
      setStage("onSave");
      let tmp = [];
      for (let item of selectedItems) {
        let obj = {
          hashtag_name: item.hashtag,
          domain_id: selectedDomain,
          permalink: item.code,
          enabled: true,
          unit_type: item.platform,
        };
        tmp.push(obj);
      }
      await updatePosts(tmp);
      openNotificationWithIcon("success", "posts added successfully");
      setStage("carousel");
      sendGtmEvent(`Save Social units Posts`, user);
    }
  };

  const onShow = async () => {
    setStage("loading");
    setSelectedItems([]);
    disableShow();
    let data = {};
    if (method === "hashtag") {
      data = {
        method: method,
        tags: tags,
        platforms: platforms,
      };
    }
    if (method === "entity") {
      data = {
        method: method,
        entity: entity,
        platforms: platforms,
      };
    }
    const response = await getSocialData(data);
    setData(response);
    setStage("result");
    sendGtmEvent("Show social units Posts", user);
  };

  const disableShow = () => {
    setDisableShowButton(true);
    setTimeout(() => setDisableShowButton(false), 10000);
  };

  const replaceSpaceRemoveNonAlphanumeric = (hashtag: string) => {
    hashtag = hashtag.replace(/ /g, "_");
    hashtag = hashtag.replace(/\W+/g, "");
    return hashtag.toLowerCase();
  };
  const onClickPlatformFilter = (platform: string) => {
    let tmp = [...filters];
    let index = tmp.indexOf(platform);
    if (index === -1) {
      tmp.push(platform);
    } else {
      tmp.splice(index, 1);
    }
    setFilters(tmp);
  };

  const platformOptions = [
    {
      key: 1,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={instagramIcon} preview={false} />
          Instagram
        </div>
      ),
      value: "instagram",
      disabled:
        method === "entity" &&
        entity.includes("@") &&
        platforms.length > 0 &&
        !platforms.includes("instagram"),
    },
    {
      key: 2,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={tiktokIcon} preview={false} />
          Tiktok
        </div>
      ),
      value: "tiktok",
      disabled:
        method === "entity" &&
        entity.includes("@") &&
        platforms.length > 0 &&
        !platforms.includes("tiktok"),
    },
    {
      key: 3,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={twittterIcon} preview={false} />
          Twitter
        </div>
      ),
      value: "twitter",
      disabled:
        method === "entity" &&
        entity.includes("@") &&
        platforms.length > 0 &&
        !platforms.includes("twitter"),
    },
  ];

  const tagRender = (option: any) => {
    let index = tags.findIndex((el) => el === option.value);
    if (index < 3 && index > -1)
      return (
        <Tag
          style={{
            background:
              COLORS.tags[tags.findIndex((el) => el === option.value)],
            color: COLORS.white,
          }}
          key={option}
        >
          #{replaceSpaceRemoveNonAlphanumeric(option.value)}
        </Tag>
      );
  };

  const getPlatformIcon = (platform: Platforms) => {
    if (platform === "instagram") return instagramIcon;
    if (platform === "tiktok") return tiktokIcon;
    if (platform === "twitter") return twittterIcon;
  };

  const getCarouselItems = (values: any, platform: string, hashtag: string) => {
    return values.map((url: string, index: number) => {
      if (platform === "instagram" && filters.includes("instagram"))
        return (
          <InstegramUnit
            width="90%"
            key={index}
            index={index}
            code={url}
            onUnitClicked={onUnitClicked}
            selectedItems={selectedItems}
            showSelectButton
            hashtag={hashtag}
            tagColor={COLORS.tags[tags.findIndex((el) => el === hashtag)]}
          />
        );
      if (platform === "tiktok" && filters.includes("tiktok"))
        return (
          <TiktokUnit
            width="90%"
            key={index}
            index={index}
            code={url}
            onUnitClicked={onUnitClicked}
            selectedItems={selectedItems}
            showSelectButton
            hashtag={hashtag}
            tagColor={COLORS.tags[tags.findIndex((el) => el === hashtag)]}
          />
        );

      if (platform === "twitter" && filters.includes("twitter"))
        return (
          <TwitterUnit
            width="90%"
            key={index}
            index={index}
            code={url}
            onUnitClicked={onUnitClicked}
            selectedItems={selectedItems}
            showSelectButton
            hashtag={hashtag}
            tagColor={COLORS.tags[tags.findIndex((el) => el === hashtag)]}
          />
        );
    });
  };

  const onSelectHashTag = (value: string) => {
    let tmp = [...tags];
    let nonLatinLettersRegex = /[^A-Za-z\d ]+/;

    if (tmp.indexOf(value) === -1) {
      replaceSpaceRemoveNonAlphanumeric(value) != "" &&
        !nonLatinLettersRegex.test(value) &&
        tmp.length < 3 &&
        tmp.push(value);
    } else {
      tmp.splice(tmp.indexOf(value), 1);
    }
    setTags(tmp);
  };

  const onDeselectHashTag = (value: string) => {
    let tmp = [...tags];
    tmp.splice(tmp.indexOf(value), 1);
    setTags(tmp);
  };

  return (
    <Row className="unit-select">
      <Row justify="space-between" className="full-width">
        <Col className="title">
          <div style={{ color: isDarkMode ? "#FDB813" : "#4097FF" }}>
            Social Unit Select
          </div>
        </Col>
        <Col className="buttons">
          <Button
            title="Show"
            onClick={() => onShow()}
            disabled={
              selectedDomain === "" ||
              !method ||
              (tags.length === 0 && entity === "") ||
              platforms.length === 0 ||
              disableShowButton ||
              !publisher
            }
            className={
              isDarkMode
                ? "general-outline-button-dark"
                : "general-outline-button-light"
            }
          />

          <Button
            permission="update:unitSelect"
            title="Save"
            onClick={save}
            disabled={selectedItems.length === 0 || stage === "carousel"}
            className={
              isDarkMode
                ? "general-outline-button-dark"
                : "general-outline-button-light"
            }
          />
        </Col>
      </Row>
      <Row className="unit-header">
        {permissions && checkPermissionOk("read:domains", permissions) && (
          <Col span={5}>
            <PublisherSelect />
          </Col>
        )}
        <Col span={4}>
          <DomainSelect
            onSelect={(value: any) => {
              setSelectedItems([]);
              setSelectedDomain(value);
            }}
          />
        </Col>

        <Col span={4}>
          <Select
            placeholder="Select search method"
            onChange={(value: Method) => {
              setTags([]);
              setEntity("");
              setPlatforms([]);
              setMethod(value);
            }}
            options={[
              { label: "Hashtag", value: "hashtag" },
              { label: "Name/@Username", value: "entity" },
            ].map((item: any) => {
              return { value: item.value, label: item.label };
            })}
          ></Select>
        </Col>

        {method === "hashtag" && (
          <Col span={4}>
            <Select
              allowClear
              placeholder="Type hashtag"
              options={[]}
              value={tags}
              mode="tags"
              showArrow={false}
              onSelect={(value) => onSelectHashTag(value)}
              onDeselect={(value) => onDeselectHashTag(value)}
              onClear={() => setTags([])}
              tagRender={tagRender}
              dropdownStyle={{ display: "none" }}
              open={false}
            ></Select>
          </Col>
        )}

        {method === "entity" && (
          <Col span={4}>
            <Input
              style={{ height: "40px" }}
              placeholder="Type name/@username"
              onChange={(e) => {
                if (e.target.value.includes("@")) setPlatforms([]);

                setEntity(e.target.value);
              }}
            ></Input>
          </Col>
        )}

        <Col span={5}>
          <Select
            placeholder="Select platform"
            mode="tags"
            onChange={(value: string[]) => {
              setPlatforms([...value]);
            }}
            value={platforms}
            options={platformOptions}
          ></Select>
        </Col>
      </Row>

      {stage === "result" && (
        <Row justify="space-between" className="full-width">
          <Col className="filters-wrapper">
            <Button
              title="All"
              onClick={() => {
                setFilters(["instagram", "tiktok", "twitter"]);
              }}
              type="default"
              size="large"
              className="filter-button"
            />

            {data.hasOwnProperty("instagram") && (
              <Button
                id={IDS.EXPLORE.INSTAGRAM_FILTER_BUTTON}
                title="Instagram"
                onClick={() => onClickPlatformFilter("instagram")}
                type="default"
                size="large"
                className="filter-button"
                style={{
                  background: filters.includes("instagram")
                    ? COLORS.buttons.surface_brand
                    : COLORS.white,
                  color: filters.includes("instagram")
                    ? COLORS.white
                    : COLORS.black,
                }}
                icon={
                  <Image
                    src={instagramIcon}
                    preview={false}
                    style={{ height: 20 }}
                  />
                }
              />
            )}

            {data.hasOwnProperty("tiktok") && (
              <Button
                id={IDS.EXPLORE.TIKTOK_FILTER_BUTTON}
                title="Tiktok"
                onClick={() => onClickPlatformFilter("tiktok")}
                type="default"
                size="large"
                className="filter-button"
                style={{
                  background: filters.includes("tiktok")
                    ? COLORS.buttons.surface_brand
                    : COLORS.white,
                  color: filters.includes("tiktok")
                    ? COLORS.white
                    : COLORS.black,
                }}
                icon={
                  <Image
                    src={tiktokIcon}
                    preview={false}
                    style={{ height: 20 }}
                  />
                }
              />
            )}

            {data.hasOwnProperty("twitter") && (
              <Button
                id={IDS.EXPLORE.TWITTER_FILTER_BUTTON}
                title="Twitter"
                onClick={() => onClickPlatformFilter("twitter")}
                type="default"
                size="large"
                className="filter-button"
                style={{
                  background: filters.includes("twitter")
                    ? COLORS.buttons.surface_brand
                    : COLORS.white,
                  color: filters.includes("twitter")
                    ? COLORS.white
                    : COLORS.black,
                }}
                icon={
                  <Image
                    src={twittterIcon}
                    preview={false}
                    style={{ height: 20 }}
                  />
                }
              />
            )}
          </Col>
          <Col className="layouts-buttons">
            <Button
              title=""
              onClick={() => setMode("carousel")}
              type="default"
              icon={
                <CarouselIcon
                  color={mode === "carousel" ? COLORS.white : COLORS.black}
                />
              }
              className="layout-button"
              style={{
                background:
                  mode === "carousel"
                    ? COLORS.buttons.surface_brand
                    : COLORS.white,
              }}
            />

            <Button
              title=""
              onClick={() => setMode("grid")}
              type="default"
              icon={
                <GridIcon
                  color={mode === "grid" ? COLORS.white : COLORS.black}
                />
              }
              className="layout-button"
              style={{
                background:
                  mode === "grid" ? COLORS.buttons.surface_brand : COLORS.white,
              }}
            />
          </Col>
        </Row>
      )}

      <Divider />

      {stage === "discover" && (
        <Row className="discover">
          <Image src={selectMessage} style={{ width: 500 }} preview={false} />
          <br />
          <br />
          <div
            style={{
              color: isDarkMode ? "white" : "#262626",
              fontSize: "36px",
            }}
          >
            Ready to Discover?
          </div>
          <div className="discover-content">
            Simply click on the
            <span style={{ fontWeight: "bold" }}>'Select Domain'</span> button
            and enter your desired
            <span style={{ fontWeight: "bold" }}>'Hashtag'</span>
          </div>
        </Row>
      )}

      {stage === "result" && (
        <Row className="full-width">
          {data &&
            Object.entries(data).map(([platform, platformData], index) => {
              return (
                filters.includes(platform) && (
                  <Row className="full-width" key={index}>
                    <Row
                      style={{
                        gap: "16px",
                        alignItems: "center",
                      }}
                      id={
                        platform === "instagram"
                          ? IDS.EXPLORE.INSTAGRAM_CONTAINER
                          : platform === "tiktok"
                          ? IDS.EXPLORE.TIKTOK_CONTAINER
                          : IDS.EXPLORE.TWITTER_CONTAINER
                      }
                    >
                      <Image
                        src={getPlatformIcon(platform)}
                        preview={false}
                        style={{ width: 40 }}
                      />
                      <h2>{`${capitalizeFirstLetter(
                        platform
                      )} Unit Select`}</h2>
                    </Row>

                    <Row key={index} className="social-items-container">
                      {platformData
                        ? Object.entries(platformData).map(
                            ([hashtag, values], platformIndex) => {
                              if (values.length > 0) {
                                if (mode === "grid")
                                  return values.map(
                                    (url: string, index: number) => {
                                      if (platform === "instagram" && index < 6)
                                        return (
                                          <InstegramUnit
                                            key={index}
                                            index={index}
                                            code={url}
                                            onUnitClicked={onUnitClicked}
                                            selectedItems={selectedItems}
                                            showSelectButton
                                            hashtag={hashtag}
                                            tagColor={
                                              COLORS.tags[platformIndex]
                                            }
                                          />
                                        );
                                      if (
                                        platform === "tiktok" &&
                                        filters.includes("tiktok") &&
                                        index < 6
                                      )
                                        return (
                                          <TiktokUnit
                                            key={index}
                                            index={index}
                                            code={url}
                                            onUnitClicked={onUnitClicked}
                                            selectedItems={selectedItems}
                                            showSelectButton
                                            hashtag={hashtag}
                                            tagColor={
                                              COLORS.tags[platformIndex]
                                            }
                                          />
                                        );

                                      if (
                                        platform === "twitter" &&
                                        filters.includes("twitter") &&
                                        index < 6
                                      )
                                        return (
                                          <TwitterUnit
                                            key={index}
                                            index={index}
                                            code={url}
                                            onUnitClicked={onUnitClicked}
                                            selectedItems={selectedItems}
                                            showSelectButton
                                            hashtag={hashtag}
                                            tagColor={
                                              COLORS.tags[platformIndex]
                                            }
                                          />
                                        );
                                    }
                                  );
                                if (mode === "carousel") {
                                  return (
                                    <AliceCarousel
                                      key={index}
                                      autoPlayDirection="ltr"
                                      items={getCarouselItems(
                                        values,
                                        platform,
                                        hashtag
                                      )}
                                      responsive={responsive}
                                      controlsStrategy="alternate"
                                      mouseTracking
                                      renderNextButton={() => {
                                        return (
                                          <Button
                                            title=""
                                            onClick={() => {}}
                                            type="default"
                                            icon={<RightOutlined size={10} />}
                                          ></Button>
                                        );
                                      }}
                                      renderPrevButton={() => {
                                        return (
                                          <Button
                                            title=""
                                            onClick={() => {}}
                                            type="default"
                                            icon={<LeftOutlined size={10} />}
                                          ></Button>
                                        );
                                      }}
                                    />
                                  );
                                }
                              } else {
                                return (
                                  <Row className="full-width" key={index}>
                                    <PureCard>
                                      <h2>
                                        There are no results at the moment for
                                        hashtag
                                        <span style={{ fontWeight: "bold" }}>
                                          #{hashtag}
                                        </span>
                                        on {platform} platform!
                                      </h2>
                                    </PureCard>
                                  </Row>
                                );
                              }
                            }
                          )
                        : null}
                    </Row>
                  </Row>
                )
              );
            })}
        </Row>
      )}

      {stage === "carousel" && (
        <Row
          style={{
            background: "",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <AliceCarousel
            autoPlayDirection="ltr"
            autoPlay
            items={selectedItems.map((item, index) => {
              if (item.platform === "instagram")
                return (
                  <InstegramUnit
                    hashtag={item.hashtag}
                    key={index}
                    index={index}
                    code={item.code}
                    selectedItems={selectedItems}
                    showSelectButton={false}
                    width="90%"
                    tagColor={
                      COLORS.tags[tags.findIndex((el) => el === item.hashtag)]
                    }
                  />
                );
              if (item.platform === "tiktok")
                return (
                  <TiktokUnit
                    width="90%"
                    key={index}
                    index={index}
                    code={item.code}
                    showSelectButton={false}
                    selectedItems={selectedItems}
                    hashtag={item.hashtag}
                    tagColor={
                      COLORS.tags[tags.findIndex((el) => el === item.hashtag)]
                    }
                  />
                );
              if (item.platform === "twitter")
                return (
                  <TwitterUnit
                    // width="90%"
                    key={index}
                    index={index}
                    code={item.code}
                    showSelectButton={false}
                    selectedItems={selectedItems}
                    hashtag={item.hashtag}
                    tagColor={
                      COLORS.tags[tags.findIndex((el) => el === item.hashtag)]
                    }
                  />
                );
            })}
            responsive={responsive}
            controlsStrategy="alternate"
          />
          <Row className="back-to-discover">
            <Button
              title="Back to discover"
              type="primary"
              size="large"
              onClick={() => {
                setSelectedItems([]);
                setPlatforms([]);
                setTags([]);
                setStage("discover");
              }}
              icon={<SearchOutlined />}
            ></Button>
          </Row>
        </Row>
      )}

      {stage === "loading" &&
        platforms.map((platform, index) => {
          return (
            <Row key={index}>
              <Row className="loading-items">
                <Image
                  src={getPlatformIcon(platform)}
                  preview={false}
                  style={{ width: 40 }}
                />
                <h2>{`${capitalizeFirstLetter(platform)} Unit Select`}</h2>
              </Row>

              <Row className="social-items-container">
                {Array(6)
                  .fill("")
                  .map((i, index) => {
                    if (platform === "instagram")
                      return (
                        <InstegramUnit
                          key={index}
                          index={index}
                          code={""}
                          onUnitClicked={onUnitClicked}
                          selectedItems={selectedItems}
                          showSelectButton
                        />
                      );
                    if (platform === "tiktok" && filters.includes("tiktok"))
                      return (
                        <TiktokUnit
                          key={index}
                          index={index}
                          code={""}
                          onUnitClicked={onUnitClicked}
                          selectedItems={selectedItems}
                          showSelectButton
                        />
                      );
                    if (platform === "twitter" && filters.includes("twitter"))
                      return (
                        <TwitterUnit
                          key={index}
                          index={index}
                          code={""}
                          onUnitClicked={onUnitClicked}
                          selectedItems={selectedItems}
                          showSelectButton
                        />
                      );
                  })}
              </Row>
            </Row>
          );
        })}

      {stage === "onSave" && <Spin tip="Please wait..." size="large"></Spin>}
      {contextHolder}
    </Row>
  );
};

export default SocialUnitSelect;
