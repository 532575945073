import { log } from "console";
import { LogFilter } from "../../utils/types";
import client from "../client";


export const getActivityLog = async (logFilter?: LogFilter) => {
  const res = await client.get("/activity_log");
  return res.data;
};

export const getUsersEmails = async () => {
  const res = await client.get("/activity_log/emails");
  return res.data;
};
