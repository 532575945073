import Modal from "../components/Modal/Modal";
import { Alert, Form, InputNumber, Row, Space } from "antd";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import Select from "../components/Select/Select";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import { IDS } from "../utils/elementsIds";
import { getLayouts } from "../api/services/Unit";
import { COLORS } from "../utils/colors";
import { Test, UserChoice } from "../utils/types";
import { getTest, insertTest } from "../api/services/Abtests";

interface AdvertisersModalProps {
  close: () => void;
  onFinish: () => void;
}

const ABtestsModal = (props: AdvertisersModalProps) => {
  const { close, onFinish } = props;
  const [stage, setStage] = useState<
    "details" | "loading" | "test" | "testExsist" | "testExsistNotActive"
  >("details");
  const [layoutsList, setLayoutsList] = useState<number[]>([]);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [test, setTest] = useState<Test>({} as Test);
  const [form] = Form.useForm();

  useEffect(() => {
    getLayoutsData();
  }, []);

  const getLayoutsData = async () => {
    let layouts = await getLayouts();
    setLayoutsList(layouts);
  };

  const setTestDetails = async () => {
    form.resetFields();
    if (stage === "details") {
      setStage("loading");
      let response: Test[] = await getTest(test);
      if (response.length > 0) {
        if (!response[0].active) {
          setStage("testExsistNotActive");
        } else {
          setStage("testExsist");
        }
      } else {
        setStage("test");
      }
    } else {
      setTest({
        ...test,
        splitA: 0,
        splitB: 0,
        userChoice: undefined,
      });
      setStage("details");
    }
  };

  const getItemTypeOptions = () => {
    let layoutsTypes = [160, 161, 162, 200, 201, 202, 210, 211];
    if (test.unitType && layoutsTypes.includes(test.unitType)) {
      return [
        { value: "tweet", label: "Tweet" },
        { value: "instagram", label: "Instagram" },
        { value: "tweetx", label: "TweetX" },
        { value: "tiktok", label: "TikTok" },
      ].map((item: any) => {
        return { value: item.value, label: item.label };
      });
    } else {
      return [
        { value: "facts", label: "Facts" },
        { value: "tips", label: "Tips" },
        { value: "onThisDay", label: "On this day" },
        { value: "tweet", label: "Tweet" },
        { value: "instagram", label: "Instagram" },
        { value: "tweetx", label: "TweetX" },
        { value: "tiktok", label: "TikTok" },
      ].map((item: any) => {
        return { value: item.value, label: item.label };
      });
    }
  };

  return (
    <Modal
      width={"60%"}
      title={<h2>{"Add New A/B test"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
    >
      <Form
        form={form}
        name="basic"
        onFinish={async () => {
          setLoading(true);
          await insertTest(test);
          setLoading(false);
          onFinish();
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="vertical"
        requiredMark
      >
        <Row
          style={{
            width: "100%",
            background: "",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            label="Choose Publisher"
            required
            rules={[
              {
                required: !test.pub_id,
                message: "Please select publisher",
              },
            ]}
            style={{ width: "31%" }}
          >
            <PublisherSelect
              id={IDS.NEW_TAG.PUBLISHER_SELECT}
              disabled={stage !== "details"}
              mode="new"
              onSelect={(pub_id, pub_key) => {
                setTest({
                  ...test,
                  pub_id: pub_id,
                  // pub_key: pub_key,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Choose Domain"
            // name="domain"
            required
            rules={[
              {
                required: !test.domain_id,
                message: "Please select domain",
              },
            ]}
            style={{ width: "31%" }}
          >
            <DomainSelect
              id={IDS.NEW_TAG.DOMAIN_SELECT}
              onSelect={(value: any) => {
                setTest({
                  ...test,
                  domain_id: value,
                });
              }}
              disabled={!test.pub_id || stage != "details"}
            />
          </Form.Item>
          <Form.Item
            label="Choose WID"
            // name="wid"
            required
            rules={[{ required: true, message: "Please select WID" }]}
            style={{ width: "31%" }}
          >
            <InputNumber
              id={IDS.NEW_TAG.WID_INPUT}
              disabled={stage !== "details"}
              placeholder="Please select WID"
              size="large"
              style={{ width: "100%" }}
              type="number"
              onChange={(value: any) => {
                setTest({
                  ...test,
                  wid: value,
                });
              }}
            />
          </Form.Item>
        </Row>

        <Button
          loading={stage === "loading"}
          style={{ width: "100%", marginBottom: "24px" }}
          type="primary"
          title={"Set test details"}
          onClick={() => setTestDetails()}
          size="middle"
          disabled={
            !test.pub_id || !test.domain_id || (!test.wid && test.wid !== 0)
          }
        ></Button>

        {stage === "test" && (
          <>
            <Row
              style={{
                width: "100%",
                background: "",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                label="Choose Split A"
                name="splitA"
                rules={[{ required: true, message: "Please select Split A" }]}
                style={{ width: "31%" }}
              >
                <InputNumber
                  size="large"
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value: any) => value!.replace("%", "")}
                  style={{ width: "100%" }}
                  onChange={(value: any) =>
                    setTest({
                      ...test,
                      splitA: value,
                      splitB: 100 - value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                // name="splitB"
                label="Choose Split B"
                rules={[{ required: true, message: "Please select Split B" }]}
                style={{ width: "31%" }}
              >
                <InputNumber
                  size="large"
                  disabled
                  value={100 - test.splitA}
                  formatter={(value) => `${value}%`}
                  parser={(value: any) => value!.replace("%", "")}
                  style={{ width: "100%" }}
                  defaultValue={`100%`}
                />
              </Form.Item>

              <Form.Item
                label="User choice"
                name="userChoice"
                rules={[
                  { required: true, message: "Please select user choice" },
                ]}
                style={{ width: "31%" }}
              >
                <Select
                  allowClear
                  placeholder="Please select User choice"
                  options={[
                    { label: "Content Type", value: "contentType" },
                    { label: "Unit Type", value: "unitType" },
                  ]}
                  onChange={(value: UserChoice) => {
                    value === "unitType" && setShowWarning(true);

                    setTest({
                      ...test,
                      userChoice: value,
                      contentType: undefined,
                      unitType: undefined,
                      itemType: undefined,
                    });
                  }}
                />
              </Form.Item>
            </Row>

            <Row
              style={{
                width: "100%",
                background: "",
                gap: `${10 / 3}%`,
              }}
            >
              {test.userChoice === "contentType" && (
                <Form.Item
                  label="Content type"
                  name="contentType"
                  rules={[
                    { required: true, message: "Please select content type" },
                  ]}
                  style={{ width: "31%" }}
                >
                  <Select
                    allowClear
                    placeholder="Please select Content type"
                    options={[
                      {
                        value: "legacy",
                        label: "Legacy",
                      },
                      { value: "ai", label: "AI" },
                    ].map((item: any) => {
                      return { value: item.value, label: item.label };
                    })}
                    onChange={(value: any) =>
                      setTest({
                        ...test,
                        contentType: value,
                      })
                    }
                  />
                </Form.Item>
              )}

              {test.userChoice === "unitType" && !showWarning && (
                <Form.Item
                  label="Unit type"
                  name="unitType"
                  rules={[
                    { required: true, message: "Please select unit type" },
                  ]}
                  style={{ width: "31%" }}
                >
                  <Select
                    allowClear
                    placeholder="Please select Unit type"
                    options={layoutsList.map((layout: any) => {
                      return { value: layout.id, label: layout.id };
                    })}
                    onChange={(value: any) =>
                      setTest({
                        ...test,
                        unitType: value,
                      })
                    }
                  />
                </Form.Item>
              )}

              {test.userChoice === "unitType" && !showWarning && (
                <Form.Item
                  label="Item type"
                  name="itemType"
                  rules={[
                    { required: true, message: "Please select item type" },
                  ]}
                  style={{ width: "31%" }}
                >
                  <Select
                    allowClear
                    placeholder="Please item type"
                    options={getItemTypeOptions()}
                    onChange={(value: any) =>
                      setTest({
                        ...test,
                        itemType: value,
                      })
                    }
                  />
                </Form.Item>
              )}
            </Row>
            {test.userChoice === "unitType" && showWarning && (
              <Row>
                <Alert
                  style={{ width: "100%", fontSize: 17 }}
                  closeIcon={false}
                  type="warning"
                  description="Make sure the A and B units are similar by type and sizes. Pay attention to Desktop/Mobile issues"
                  action={
                    <Space>
                      <Button
                        style={{
                          marginLeft: "16px",
                          background: COLORS.antd.warning,
                        }}
                        type="primary"
                        size="small"
                        title="OK"
                        onClick={() => setShowWarning(false)}
                      ></Button>
                    </Space>
                  }
                  closable
                />
              </Row>
            )}

            <Row
              style={{
                justifyContent: "center",
                gap: "16px",
                marginTop: "24px",
              }}
            >
              <Button type="default" title="Cancel" onClick={close}></Button>
              <Button
                disabled={showWarning}
                id={IDS.NEW_TAG.ADD_BUTTON}
                type="primary"
                title={"Add A/B Test"}
                loading={loading}
                htmlType="submit"
              ></Button>
            </Row>
          </>
        )}
        {(stage === "testExsist" || stage === "testExsistNotActive") && (
          <Row>
            <Alert
              style={{ width: "100%", fontSize: 17 }}
              closeIcon={false}
              type="error"
              description={
                stage === "testExsist"
                  ? "There is an active A/B test for this WID. Change the WID or turn the active test to non-active in order to continue"
                  : "Test exsist please activate"
              }
              action={
                <Space>
                  <Button
                    style={{
                      marginLeft: "16px",
                      background: COLORS.antd.error,
                    }}
                    type="primary"
                    size="small"
                    title="OK"
                    onClick={() => setStage("details")}
                  ></Button>
                </Space>
              }
              closable
            />
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default ABtestsModal;
