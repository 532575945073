import React from "react";
import "./styles.scss";
import { Tabs, TabsProps } from "antd";
import AdsTxtSingelLine from "./AdsTxtSingelLine";
import AdsTxtMultiLine from "./AdsTxtMultiLine";

const AdsTxtManager = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Single line`,
      children: <AdsTxtSingelLine />,
    },
    {
      key: "2",
      label: `Multi lines`,
      children: <AdsTxtMultiLine />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
      onChange={() => {}}
      centered
      size="large"
    />
  );
};
export default AdsTxtManager;
