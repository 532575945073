import { Row, Input, Form, Tooltip, Checkbox } from "antd";
import { Publisher } from "../../utils/types";
import TextArea from "antd/es/input/TextArea";
import DomainsTable from "./DomainTablesModal";
import { InfoCircleOutlined } from "@ant-design/icons";

interface PublisherModalProps {
  publisherData: Publisher;
}

const PublisherDetails = (props: PublisherModalProps) => {
  const { publisherData } = props;

  return (
    <div>
      <Form
        name="publisher-details"
        autoComplete="off"
        layout="vertical"
        requiredMark
        style={{ paddingRight: "10%", paddingLeft: "5.5%" }}
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Form.Item
            label="Contact Name"
            style={{ width: "17%", marginRight: 1.8 }}
          >
            <Input
              readOnly={true}
              size="large"
              style={{
                marginBottom: 22,
                width: "90%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.pub_contact_name || "none"}
            />
          </Form.Item>
          <Form.Item label="Publisher Email" style={{ width: "29%" }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                marginBottom: 22,
                width: "95%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.pub_email || "none"}
            />
          </Form.Item>
          <Form.Item label="Contract type" style={{ width: "16.5%" }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={
                publisherData.contract_type === 3
                  ? "Rev Share"
                  : publisherData.contract_type === 4
                  ? "Tech Fee"
                  : "none"
              }
            />
          </Form.Item>
          <Form.Item label="Rev Share" style={{ width: "12%" }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                width: "90%",
                marginBottom: 22,
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={(publisherData.cpm * 100).toFixed(1) + " %"}
            />
          </Form.Item>
        </Row>
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Form.Item label="Terms Of Payment" style={{ width: "17%" }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                width: "90%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.terms_of_payment_in_days || "none"}
            />
            <Checkbox
              checked={publisherData.InCorporated}
              defaultChecked={publisherData.InCorporated}
              style={{ marginTop: "20%" }}
              onChange={() => {}}
            >
              Is EU InCorporated
            </Checkbox>
          </Form.Item>
          <Form.Item label="Location" style={{ width: "29%", marginLeft: 3 }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                width: "95%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.location || "none"}
            />
            <div style={{ marginTop: "12%" }}>
              <Tooltip
                placement="topLeft"
                title={
                  <span>
                    Publisher Id: {publisherData.pub_id} <br />
                    Public Key: {publisherData.public_key}
                  </span>
                }
              >
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
          </Form.Item>
          <Form.Item
            label="Dashbboard Message Pop-Up"
            style={{ width: "37%", paddingLeft: 0.8 }}
          >
            <TextArea
              readOnly={true}
              size={
                publisherData.dashboard_popup_message_url ? "large" : "small"
              }
              style={{
                width: "97%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={
                publisherData.dashboard_popup_message_url || "No popup Message"
              }
            />
          </Form.Item>
        </Row>
      </Form>

      <DomainsTable
        pub_id={publisherData.pub_id}
        pub_key={publisherData.public_key}
      />
    </div>
  );
};

export default PublisherDetails;
