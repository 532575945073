import { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import { Affix, Form, Input, Row, Spin, Tooltip, Popconfirm } from "antd";
import "./styles.scss";
import type { Color } from "antd/es/color-picker";
import Button from "../components/Button";
import Select from "../components/Select/Select";
import ColorPickerUnit from "../pages/Publishers/ColorPickerUnit";
import { Mode, DefaultAiCategoriesType } from "../utils/types";
import AlertModal from "./AlertModal";
import Widget from "../pages/Publishers/Widget";
import { getDomainSettings } from "../api/services/Domains";
import { useSelector } from "react-redux";
import { AppState } from "../store/store";
import { PlusCircleFilled, DeleteOutlined } from "@ant-design/icons";
import Item from "antd/es/list/Item";
import { COLORS } from "../utils/colors.js";
import { createDefaultWidget, validatorInput } from "../utils/utils";
import { Empty } from "antd";
import {
  getAllLayoutTypes,
  getAllWidgetsNumbers,
  getAllItemsTypes,
  getAllWidgetsData,
  getAllDefaultAiCategories,
} from "../api/services/Widgets";

interface NewDomainProps {
  close: () => void;
  onFinish: (
    domain: any,
    widgets: any[],
    inContentThemeColor: Color | string,
    inContentThemeHoverColor: Color | string,
    inContentTitleBGColor: Color | string,
    defaultAiCategory: string | null,
    widgetsToDelete?: any,
    widgetsOriginalData?: any,
    OriginaldefaultAiCategory?: string,
    defaultRevenueSource?: string | null
  ) => void;
  data?: any;
  mode: Mode;
  pub_id: number;
  pub_key: string;
}

const NewDomainModal = (props: NewDomainProps) => {
  const { close, data, onFinish, mode, pub_id, pub_key } = props;
  const defaultDomain = {
    domain_id: 0,
    ref_pub_id: pub_id,
    url: "",
    name: "",
  };

  const [finalDomain, setFinalDomain] = useState<any>(
    data ? data : defaultDomain
  );
  const [widgets, setWidgets] = useState<any[]>([]);
  const [widgetsToDelete, setWidgetsToDelete] = useState<any>([]);
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const [showCheckEditModal, setShowCheckEditModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [widgetsLoading, setWidgetsLoading] = useState<number>(0);
  const [isSet, setisSet] = useState<boolean>(false);
  const [allWidgets, setAllWidgets] = useState<any>([]);
  const [allLayoutTypes, setAllLayoutTypes] = useState<any>([]);
  const [allItemTypes, setAllItemTypes] = useState<any>([]);
  const [DefaultAiCategories, setAllDefaultAiCategories] = useState<
    DefaultAiCategoriesType[]
  >([]);
  const [InContentThemeColor, setInContentThemeColor] = useState<
    Color | string
  >(isDarkMode ? "rgb(218, 159, 19)" : "rgb(64, 151, 255)");
  const [InContentThemeHoverColor, setInContentThemeHoverColor] = useState<
    Color | string
  >(isDarkMode ? "rgb(218, 159, 19)" : "rgb(64, 151, 255)");
  const [InContentTitleBGColor, setInContentTitleBGColor] = useState<
    Color | string
  >(isDarkMode ? "rgb(218, 159, 19)" : "rgb(64, 151, 255)");
  const [defaultAiCategory, setDefaultAiCategory] = useState<string | null>(
    null
  );
  const [defaultRevenueSource, setDefaultRevenueSource] = useState<string | null>(null);
  const isEmpty = widgets.length == 0 && mode == "edit";
  const [form] = Form.useForm();
  const [widgetsOriginalData, setWidgetsOriginalData] = useState<any[]>([]);
  const [OriginaldefaultAiCategory, setOriginaldefaultAiCategory] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    const initializeState = async () => {
      setLoading(true);
      await getSettingsData();
    };

    initializeState();
  }, []);

  useEffect(() => {
    getWidgetData();
  }, []);

  const getWidgetData = async () => {
    const originalData = await getAllWidgetsData(pub_id, data?.domain_id);
    setWidgets(originalData || [createDefaultWidget(pub_id)]);
    setWidgetsOriginalData(originalData);
  };
  const handleWidgetsLoad = () => {
    setWidgetsLoading((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (widgetsLoading === widgets.length && !isEmpty && isSet) {
      setWidgetsLoading(0);
      setLoading(false);
    }
  }, [widgetsLoading, data, isSet]);

  const getSettingsData = async () => {
    setLoading(true);
    let resWids = await getAllWidgetsNumbers();
    let arrWID = resWids.map((wid: any) => wid.widget_id);
    let resLayouts = await getAllLayoutTypes();
    let arrLayouts = resLayouts.map((layout: any) => layout.layout_type);
    let resItems = await getAllItemsTypes();
    let arrItems = resItems.map((item: any) => item.item_type);
    if (data) {
      let settings = await getDomainSettings(data.domain_id);
      const findDefaultRevenue = settings.filter((el: any) => el.key === 'defaultRevenueSource');
      
      if(findDefaultRevenue.length === 0) {
        settings.push({key: 'defaultRevenueSource', value: 'carambola'});
      }

      settings.map((setting: any) => {
        switch (setting.key) {
          case "InContentThemeColor":
            setInContentThemeColor(setting.value);
            break;
          case "InContentThemeHoverColor":
            setInContentThemeHoverColor(setting.value);
            break;
          case "InContentTitleBGColor":
            setInContentTitleBGColor(setting.value);
            break;
          case "defaultAiCategory":
            setDefaultAiCategory(setting.value);
            setOriginaldefaultAiCategory(setting.value);
            break;
          case "defaultRevenueSource":
            setDefaultRevenueSource(setting.value);
            break;            
          default:
            break;
        }
      });
    }

    setAllWidgets(arrWID);
    setAllLayoutTypes(arrLayouts);
    setAllItemTypes(arrItems);
    setAllDefaultAiCategories(await getAllDefaultAiCategories());
    if (isEmpty) {
      setLoading(false);
    }
    setisSet(true);
  };

  const onUpdateWidget = (newWidget: any) => {
    const widgetIndex = widgets.findIndex(
      (widget: any) => widget.id === newWidget.id
    );

    if (widgetIndex !== -1) {
      setWidgets((prevWidgets: any) => {
        const updatedWidgets = [...prevWidgets];
        updatedWidgets[widgetIndex] = newWidget;
        return updatedWidgets;
      });
    } else {
      if (newWidget.id) {
        setWidgets((prevWidgets: any) => [...prevWidgets, newWidget]);
      }
    }
  };

  const handleDeleteWidget = (deleteWidget: any, index: number) => {
    if (typeof deleteWidget.id != "string") {
      setWidgetsToDelete((prevWidgets: any) => [...prevWidgets, deleteWidget]);
    }
    let newWidgets = [...widgets];
    newWidgets.splice(index, 1);
    setWidgets(newWidgets);
  };

  return (
    <Modal
      width={"85%"}
      title={
        <h2 className="padding">
          {mode === "new" ? "Add New Domain to Publisher" : "Edit Domain"}
        </h2>
      }
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
      className="new-domain-modal"
    >
      {loading && (
        <Spin tip="Loading" size="large">
          <div className="loading-spinner" />
        </Spin>
      )}
      {!loading && (
        <Form
          form={form}
          name="new-domain"
          onFinish={() => {
            if (mode === "edit") {
              setShowCheckEditModal(true);
            } else {
              onFinish(
                finalDomain,
                widgets,
                InContentThemeColor,
                InContentThemeHoverColor,
                InContentTitleBGColor,
                defaultAiCategory,
                defaultRevenueSource || "carambola",
                pub_key,
                OriginaldefaultAiCategory,
              );
            }
          }}
          onFinishFailed={() => {}}
          autoComplete="off"
          layout="vertical"
          requiredMark
          initialValues={{
            ["name"]: finalDomain.name,
            ["url"]: finalDomain.url,
            ["revenueSource"]: defaultRevenueSource || "carambola",
            ["defaultAICategory"]: defaultAiCategory || "Select Default AI Category"
          }}
        >
          <Affix
            style={{
              position: "sticky",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="padding">
              <Row justify="space-between">
                <Form.Item
                  label="Choose Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Choose Domain Name",
                    },
                    {
                      validator: async (_, value) => {
                        await validatorInput(value, "Name", 35);
                      },
                    },
                  ]}
                  className="form-item"
                >
                  <Input
                    placeholder="Please Choose Domain Name"
                    size="large"
                    value={finalDomain.pub_name}
                    onChange={(e) => {
                      setFinalDomain({
                        ...finalDomain,
                        name: e.target.value.trim(),
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Choose URL"
                  name="url"
                  rules={[
                    { required: true, message: "Please Choose Domain URL" },
                    {
                      validator: async (_, value) => {
                        await validatorInput(value, "Url", 45);
                      },
                    },
                  ]}
                  className="form-item"
                >
                  <Input
                    placeholder="Please Choose Domain URL"
                    size="large"
                    value={finalDomain.pub_name}
                    onChange={(e) => {
                      setFinalDomain({
                        ...finalDomain,
                        url: e.target.value.trim(),
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="defaultAICategory"
                  label="Select Default AI Category"
                  className="form-item"
                >
                  {
                    <Select
                      allowClear
                      placeholder="Select Default AI Category"

                      options={DefaultAiCategories.map((category: any) => {
                        return {
                          label: category.category,
                          value: category.category,
                        };
                      })}
                      onSelect={(defaultAICategoryValue) => {
                        setDefaultAiCategory(defaultAICategoryValue);
                      }}
                    />
                  }
                </Form.Item>

                <Form.Item
                  name="revenueSource"
                  label="Select Revenue Source"
                  className="form-item"
                >
                  {
                    <Select
                      allowClear
                      placeholder="Select Revenue Source"
                      options={[
                        {
                          label: "Carambola",
                          value: "carambola"
                        },
                        {
                          label: "Assertive Yield",
                          value: "assertive"
                        }
                      ]}
                      onSelect={(defaultRevenueSource) => {
                        setDefaultRevenueSource(defaultRevenueSource);
                      }}
                    />
                  }
                </Form.Item>

              </Row>
              <Row style={{ marginTop: 4, justifyContent: "start" }}>
                <ColorPickerUnit
                  keyColor={"Theme Color"}
                  colorRgb={InContentThemeColor}
                  setColorRgb={setInContentThemeColor}
                />
                <ColorPickerUnit
                  keyColor={"Theme Hover Color"}
                  colorRgb={InContentThemeHoverColor}
                  setColorRgb={setInContentThemeHoverColor}
                />
                <ColorPickerUnit
                  keyColor={"Title BG Color"}
                  colorRgb={InContentTitleBGColor}
                  setColorRgb={setInContentTitleBGColor}
                />
              </Row>
            </div>
          </Affix>

          <Row>
            <Item style={{ width: "94%" }}>
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "400px",
                  marginTop: 10,
                }}
                className="padding"
              >
                {widgets.map((wid: any, index: number) => {
                  return (
                    <Row
                      key={wid.id}
                      style={{ justifyContent: "space-between" }}
                    >
                      <Item style={{ width: "96%" }}>
                        <Widget
                          key={wid.id}
                          data={wid}
                          pub_id={pub_id}
                          onLoad={handleWidgetsLoad}
                          onUpdateWidget={onUpdateWidget}
                          mode={mode}
                          allWidgets={allWidgets}
                          allItemTypes={allItemTypes}
                          allLayoutTypes={allLayoutTypes}
                        />
                      </Item>
                      <Popconfirm
                        title="Delete"
                        description="Are you sure yo want to delete the widget?"
                        placement="topLeft"
                        onConfirm={() => handleDeleteWidget(wid, index)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined
                          style={{
                            fontSize: "20px",
                            fontWeight: 40,
                            height: 20,
                            marginTop: 40,
                          }}
                        />
                      </Popconfirm>
                    </Row>
                  );
                })}
                {isEmpty && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              </div>
            </Item>

            <Item className="item-add-button">
              <Affix>
                <Button
                  size="small"
                  icon={
                    <Tooltip
                      placement="topLeft"
                      title={"Add widget"}
                      overlayStyle={{ maxWidth: "400px" }}
                    >
                      <span className="icon">
                        <PlusCircleFilled
                          style={{
                            fontSize: "30px",
                            color: isDarkMode
                              ? COLORS.darkMode.main
                              : COLORS.buttons.surface_brand,
                          }}
                        />
                      </span>
                    </Tooltip>
                  }
                  type="ghost"
                  shape="circle"
                  onClick={() => {
                    onUpdateWidget(createDefaultWidget(pub_id));
                  }}
                />
              </Affix>
            </Item>
          </Row>
          <Row className="buttons">
            <Button type="default" title="Cancel" onClick={close}></Button>
            <Button
              type="primary"
              title={"Save"}
              onClick={() => {}}
              htmlType="submit"
            ></Button>
          </Row>
        </Form>
      )}

      {showCheckEditModal && (
        <AlertModal
          alertText="Are you sure you want to edit?"
          titleOnOK="SAVE CHANGES"
          titleOnCancle="CANCEL"
          onClose={() => {
            setShowCheckEditModal(false);
          }}
          isModalOpen={showCheckEditModal}
          onOk={() => {
            setShowCheckEditModal(false);
            onFinish(
              finalDomain,
              widgets,
              InContentThemeColor,
              InContentThemeHoverColor,
              InContentTitleBGColor,
              defaultAiCategory,
              widgetsToDelete,
              widgetsOriginalData,
              OriginaldefaultAiCategory,
              defaultRevenueSource
            );
          }}
        />
      )}
    </Modal>
  );
};

export default NewDomainModal;
