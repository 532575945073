import { Row, Skeleton, Table } from "antd";
import { useEffect } from "react";
import dayjs from "dayjs";
import "./styles.scss";
import { getDataSource } from "../../utils/utils";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";

interface IntervalTableProps {
  data: any;
  isLoading: boolean;
}

const IntervalTable = (props: IntervalTableProps) => {
  const { data, isLoading } = props;
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  useEffect(() => {}, [data]);

  const getColumns = () => {
    let tmp: any[] = [];
    tmp.push({
      title: "Date",
      dataIndex: "date",
      key: "date",
      // sorter: (a: any, b: any) => {
      //   return dayjs(a.date).unix() - dayjs(b.date).unix();
      // },
    });
    for (let item of data.IntervalDataTitles) {
      let obj: any = {};
      obj["title"] = item;
      obj["dataIndex"] = item;
      obj["key"] = item;
      tmp.push(obj);
    }

    return tmp;
  };

  return (
    <Row gutter={16}>
      {isLoading || !data ? (
        <Skeleton loading={true} avatar active></Skeleton>
      ) : (
        <Table
          style={{ width: "100%" }}
          dataSource={getDataSource(data)}
          columns={getColumns()}
          className={
            isDarkMode ? "table-striped-rows-dark" : "table-striped-rows-light"
          }
          rowKey={"date"}
        />
      )}
    </Row>
  );
};

export default IntervalTable;
