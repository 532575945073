import { useState } from "react";
import Modal from "../components/Modal/Modal";
import { Row } from "antd";
import Select from "../components/Select/Select";

interface GdprModalProps {
  onClose: () => void;
  onOk: (selectedItems: string[]) => void;
  options: any[];
  mapOptions: any;
}

const DomainsModal = (props: GdprModalProps) => {
  const { onClose, options, onOk, mapOptions } = props;
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  return (
    <Modal
      width={"50%"}
      height={150}
      title={<h2>Add domain to Active Domains Table </h2>}
      isModalOpen
      okText={"Add"}
      okDisabled={selectedItems.length === 0}
      onOk={() => onOk(selectedItems)}
      setIsModalOpen={onClose}
    >
      <Row style={{ marginTop: 24 }}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: "70%" }}
          placeholder="Please select domain"
          onSelect={(value) => {
            let selectedItemsTmp = [...selectedItems];
            selectedItemsTmp.push(mapOptions[value]);
            setSelectedItems(selectedItemsTmp);
          }}
          onDeselect={(value: string) => {
            let selectedItemsTmp = [...selectedItems];
            selectedItemsTmp.splice(
              selectedItemsTmp.indexOf(mapOptions[value]),
              1
            );
            setSelectedItems(selectedItemsTmp);
          }}
          onClear={() => {
            setSelectedItems([]);
          }}
          options={options}
        />
      </Row>
    </Modal>
  );
};

export default DomainsModal;
