export const CarouselIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7142 1.78564H11.5714C10.8616 1.78564 10.2856 2.93764 10.2856 4.35707V14.6428C10.2856 16.0622 10.8616 17.2142 11.5714 17.2142H16.7142C17.4239 17.2142 17.9999 16.0622 17.9999 14.6428V4.35707C17.9999 2.93764 17.4239 1.78564 16.7142 1.78564Z"
        fill={color}
      />
      <path
        d="M6.42857 1.78564H1.28571C0.576 1.78564 0 2.93764 0 4.35707V14.6428C0 16.0622 0.576 17.2142 1.28571 17.2142H6.42857C7.13829 17.2142 7.71429 16.0622 7.71429 14.6428V4.35707C7.71429 2.93764 7.13829 1.78564 6.42857 1.78564Z"
        fill={color}
      />
    </svg>
  );
};
