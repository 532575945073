import { Button as AntDesignButton, Space } from "antd";
import "./styles.scss";
import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store/store";
import { checkPermissionOk } from "../utils/utils";

interface ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  size?: "large" | "middle" | "small";
  type?: "primary" | "dashed" | "ghost" | "link" | "text" | "default";
  ghost?: boolean;
  icon?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  title?: string;
  htmlType?: "button" | "submit" | "reset";
  loading?: boolean;
  id?: string;
  shape?: "default" | "circle" | "round";
  permission?: string;
}

const Button = (props: ButtonProps) => {
  const {
    title,
    disabled,
    size,
    onClick,
    type,
    ghost,
    icon,
    className,
    style,
    htmlType,
    loading,
    id,
    shape,
    permission,
  } = props;

  const { permissions } = useSelector((state: AppState) => state);

  if (permission) {
    return permissions && checkPermissionOk(permission, permissions) ? (
      <AntDesignButton
        onClick={onClick}
        disabled={disabled}
        size={size || "large"}
        type={type || "default"}
        ghost={ghost || false}
        icon={icon}
        shape={shape}
        {...(className && { className })}
        {...(style && { style })}
        {...(htmlType && { htmlType })}
        {...(id && { id })}
        loading={loading}
      >
        {title}
      </AntDesignButton>
    ) : null;
  } else {
    return (
      <AntDesignButton
        onClick={onClick}
        disabled={disabled}
        size={size || "large"}
        type={type || "default"}
        ghost={ghost || false}
        icon={icon}
        shape={shape}
        {...(className && { className })}
        {...(style && { style })}
        {...(htmlType && { htmlType })}
        {...(id && { id })}
        loading={loading}
      >
        {title}
      </AntDesignButton>
    );
  }
};

export default Button;
