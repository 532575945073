import React, { useEffect, useState } from "react";
import { Row, Switch, Table, Tooltip, notification } from "antd";
import Button from "../../components/Button";
import PureCard from "../../components/PureCard/PureCard";
import { NotificationType } from "../../utils/notifictions";
import ABtestsModal from "../../modals/ABtestsModal";
import { Test } from "../../utils/types";
import { getABtests, updateTest } from "../../api/services/Abtests";
import { EditIcon } from "../../assets/images/edit";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { COLORS } from "../../utils/colors";
import "./styles.scss";
import ABtestsEditModal from "../../modals/ABtestsEditModal";

const ABtests = () => {
  const [data, setData] = useState<Test[]>([]);
  const [isABtestModalOpen, setIsABtestModalOpen] = useState(false);
  const [isABtestEditModalOpen, setIsABtestEditModalOpen] = useState(false);
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [test, setTest] = useState<Test>({} as Test);

  useEffect(() => {
    getData();
  }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const getData = async () => {
    setIsLoading(true);
    let response = await getABtests();
    setData(response);
    setIsLoading(false);
  };

  const getColumns = () => {
    return [
      {
        title: "Publisher Name",
        key: "pub_name",
        dataIndex: "pub_name",
      },
      {
        title: "Domain Name",
        key: "domain_name",
        dataIndex: "domain_name",
      },

      {
        title: "Wid",
        key: "wid",
        dataIndex: "wid",
      },
      {
        title: "Enabled / Disabled",
        key: "active",
        dataIndex: "active",
        width: "10%",
        render: (_: any, record: Test) => (
          <Row style={{ justifyContent: "center" }}>
            <Switch
              size="small"
              onChange={async (value) => {
                await updateTest({
                  id: record.id,
                  active: value,
                } as Test);
                onFinishEdit();
              }}
              defaultChecked={record.active}
            />
          </Row>
        ),
      },
      {
        title: "Operation",
        dataIndex: "operation",
        key: "operation",
        width: "15%",
        render: (_: any, test: Test) => (
          <Row
            style={{
              justifyContent: "center",
              width: "100%",
              alignSelf: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Button
              type="default"
              onClick={() => {
                setIsABtestEditModalOpen(true);
                setTest(test);
              }}
              icon={
                <Tooltip
                  placement="topLeft"
                  title={"Edit"}
                  overlayStyle={{ maxWidth: "400px" }}
                >
                  <span className="icon">
                    <EditIcon
                      color={isDarkMode ? COLORS.darkMode.main : "#262626"}
                    />
                  </span>
                </Tooltip>
              }
            ></Button>
          </Row>
        ),
      },
    ];
  };

  const getDataSource = () => {
    let tmp: any[] = [];
    if (data) {
      for (let index = 0; index < data.length; index++) {
        let item = data[index];
        item.key = index.toString();
        tmp.push(item);
      }
    }
    return tmp;
  };

  const onFinish = () => {
    openNotificationWithIcon("success", "test added successfully");
    setIsABtestModalOpen(false);
    getData();
  };

  const onFinishEdit = () => {
    openNotificationWithIcon("success", "test updated successfully");
    setIsABtestEditModalOpen(false);
    getData();
  };
  return (
    <div className="abtests">
      {contextHolder}

      <PureCard>
        <Row justify={"end"} style={{ marginBottom: 24 }}>
          <Button
            title="New A/B test"
            type="primary"
            onClick={() => setIsABtestModalOpen(true)}
          ></Button>
        </Row>
        <Table
          columns={getColumns()}
          dataSource={getDataSource()}
          loading={isLoading}
        />
      </PureCard>
      {isABtestModalOpen && (
        <ABtestsModal
          close={() => setIsABtestModalOpen(false)}
          onFinish={onFinish}
        />
      )}

      {isABtestEditModalOpen && (
        <ABtestsEditModal
          close={() => setIsABtestEditModalOpen(false)}
          onFinish={onFinishEdit}
          data={test}
        />
      )}
    </div>
  );
};

export default ABtests;
