import Modal from "../components/Modal/Modal";
import { Alert, Col, Form, InputNumber, Row, Space, Tag } from "antd";
import Select from "../components/Select/Select";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import { IDS } from "../utils/elementsIds";
import { getLayouts } from "../api/services/Unit";
import { COLORS } from "../utils/colors";
import { Test, UserChoice } from "../utils/types";
import { updateTest } from "../api/services/Abtests";
import { useSelector } from "react-redux";
import { AppState } from "../store/store";

interface AdvertisersModalProps {
  close: () => void;
  onFinish: () => void;
  data: Test;
}

const ABtestsEditModal = (props: AdvertisersModalProps) => {
  const { close, data, onFinish } = props;
  const [layoutsList, setLayoutsList] = useState<number[]>([]);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [test, setTest] = useState<Test>({} as Test);
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  const [form] = Form.useForm();

  useEffect(() => {
    getLayoutsData();
  }, []);

  useEffect(() => {
    setTest({ ...data });
  }, []);

  const getLayoutsData = async () => {
    let layouts = await getLayouts();
    setLayoutsList(layouts);
  };

  const getItemTypeOptions = () => {
    let layoutsTypes = [160, 161, 162, 200, 201, 202, 210, 211];
    if (test.unitType && layoutsTypes.includes(test.unitType)) {
      return [
        { value: "tweet", label: "Tweet" },
        { value: "instagram", label: "Instagram" },
        { value: "tweetx", label: "TweetX" },
        { value: "tiktok", label: "TikTok" },
      ].map((item: any) => {
        return { value: item.value, label: item.label };
      });
    } else {
      return [
        { value: "facts", label: "Facts" },
        { value: "tips", label: "Tips" },
        { value: "onThisDay", label: "On this day" },
        { value: "tweet", label: "Tweet" },
        { value: "instagram", label: "Instagram" },
        { value: "tweetx", label: "TweetX" },
        { value: "tiktok", label: "TikTok" },
      ].map((item: any) => {
        return { value: item.value, label: item.label };
      });
    }
  };

  return (
    <Modal
      width={"60%"}
      title={<h2>{"Add New A/B test"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
    >
      {Object.keys(test).length != 0 && (
        <Form
          form={form}
          name="basic"
          onFinish={async () => {
            setLoading(true);
            await updateTest({
              id: test.id,
              splitA: test.splitA,
              splitB: test.splitB,
              userChoice: test.userChoice,
              contentType: test.contentType,
              unitType: test.unitType,
              itemType: test.itemType,
              active: test.active,
            } as Test);
            setLoading(false);
            onFinish();
          }}
          onFinishFailed={() => {}}
          autoComplete="off"
          layout="vertical"
          requiredMark
          initialValues={{
            ["splitA"]: data?.splitA,
            ["splitB"]: data?.splitB,
            ["userChoice"]: data?.userChoice,
            ["unitType"]: data?.unitType,
            ["itemType"]: data?.itemType,
            ["contentType"]: data?.contentType,
          }}
        >
          <Row
            style={{
              width: "100%",
              background: "",
              justifyContent: "space-between",
              marginTop: "24px",
              marginBottom: "24px",
            }}
          >
            <Col>
              <Tag color={COLORS.labels.border_primary}>{`Publisher name`}</Tag>

              <span
                style={{ color: isDarkMode ? COLORS.darkMode.main : "navy" }}
              >
                {test.pub_name}
              </span>
            </Col>

            <Col>
              <Tag color={COLORS.labels.border_primary}>{`Domain name`}</Tag>

              <span
                style={{ color: isDarkMode ? COLORS.darkMode.main : "navy" }}
              >
                {test.domain_name}
              </span>
            </Col>

            <Col>
              <Tag color={COLORS.labels.border_primary}>{`WID`}</Tag>

              <span
                style={{ color: isDarkMode ? COLORS.darkMode.main : "navy" }}
              >
                {test.wid}
              </span>
            </Col>
          </Row>

          {
            <>
              <Row
                style={{
                  width: "100%",
                  background: "",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  label="Choose Split A"
                  name="splitA"
                  rules={[{ required: true, message: "Please select Split A" }]}
                  style={{ width: "31%" }}
                >
                  <InputNumber
                    size="large"
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    style={{ width: "100%" }}
                    onChange={(value: any) =>
                      setTest({
                        ...test,
                        splitA: value,
                        splitB: 100 - value,
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  // name="splitB"
                  label="Choose Split B"
                  rules={[{ required: true, message: "Please select Split B" }]}
                  style={{ width: "31%" }}
                >
                  <InputNumber
                    defaultValue={`${test?.splitB}%`}
                    size="large"
                    disabled
                    value={100 - test.splitA}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                <Form.Item
                  label="User choice"
                  name="userChoice"
                  rules={[
                    { required: true, message: "Please select user choice" },
                  ]}
                  style={{ width: "31%" }}
                >
                  <Select
                    allowClear
                    placeholder="Please select User choice"
                    options={[
                      { label: "Content Type", value: "contentType" },
                      { label: "Unit Type", value: "unitType" },
                    ]}
                    onChange={(value: UserChoice) => {
                      value === "unitType" && setShowWarning(true);
                      setTest({
                        ...test,
                        userChoice: value,
                        contentType: null,
                        unitType: null,
                        itemType: null,
                      });
                    }}
                  />
                </Form.Item>
              </Row>

              <Row
                style={{
                  width: "100%",
                  background: "",
                  gap: `${10 / 3}%`,
                }}
              >
                {test.userChoice === "contentType" && (
                  <Form.Item
                    label="Content type"
                    name="contentType"
                    rules={[
                      { required: true, message: "Please select content type" },
                    ]}
                    style={{ width: "31%" }}
                  >
                    <Select
                      value={test.contentType || undefined}
                      allowClear
                      placeholder="Please select Content type"
                      options={[
                        {
                          value: "legacy",
                          label: "Legacy",
                        },
                        { value: "ai", label: "AI" },
                      ].map((item: any) => {
                        return { value: item.value, label: item.label };
                      })}
                      onChange={(value: any) =>
                        setTest({
                          ...test,
                          contentType: value,
                        })
                      }
                    />
                  </Form.Item>
                )}

                {test.userChoice === "unitType" && !showWarning && (
                  <Form.Item
                    label="Unit type"
                    name="unitType"
                    rules={[
                      { required: true, message: "Please select unit type" },
                    ]}
                    style={{ width: "31%" }}
                  >
                    <Select
                      allowClear
                      placeholder="Please select Unit type"
                      options={layoutsList.map((layout: any) => {
                        return { value: layout.id, label: layout.id };
                      })}
                      onChange={(value: any) =>
                        setTest({
                          ...test,
                          unitType: value,
                        })
                      }
                    />
                  </Form.Item>
                )}

                {test.userChoice === "unitType" && !showWarning && (
                  <Form.Item
                    label="Item type"
                    name="itemType"
                    rules={[
                      { required: true, message: "Please select item type" },
                    ]}
                    style={{ width: "31%" }}
                  >
                    <Select
                      allowClear
                      placeholder="Please item type"
                      options={getItemTypeOptions()}
                      onChange={(value: any) =>
                        setTest({
                          ...test,
                          itemType: value,
                        })
                      }
                    />
                  </Form.Item>
                )}
              </Row>
              {test.userChoice === "unitType" && showWarning && (
                <Row>
                  <Alert
                    style={{ width: "100%", fontSize: 17 }}
                    closeIcon={false}
                    type="warning"
                    description="Make sure the A and B units are similar by type and sizes. Pay attention to Desktop/Mobile issues"
                    action={
                      <Space>
                        <Button
                          style={{
                            marginLeft: "16px",
                            background: COLORS.antd.warning,
                          }}
                          type="primary"
                          size="small"
                          title="OK"
                          onClick={() => setShowWarning(false)}
                        ></Button>
                      </Space>
                    }
                    closable
                  />
                </Row>
              )}
              <Row
                style={{
                  justifyContent: "center",
                  gap: "16px",
                  marginTop: "24px",
                }}
              >
                <Button type="default" title="Cancel" onClick={close}></Button>
                <Button
                  disabled={showWarning}
                  id={IDS.NEW_TAG.ADD_BUTTON}
                  type="primary"
                  title={"Save A/B Test"}
                  loading={loading}
                  htmlType="submit"
                ></Button>
              </Row>
            </>
          }
        </Form>
      )}
    </Modal>
  );
};

export default ABtestsEditModal;
