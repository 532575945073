import { useEffect, useState } from "react";
import { getDomains } from "../../api/services/Domains";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import Select from "../../components/Select/Select";
import { AppState } from "@auth0/auth0-react";
import { Mode } from "../../utils/types";

interface PublisherSelectProps {
  mode?: Mode;
  setDisabled?: (flag: boolean) => void;
  onSelect?: (pub_id: any, pub_key: any) => void;
  allowClear?: boolean;
  disabled?: boolean;
  value?: number;
  defaultValue?: string;
  id?: string;
}

const PublisherSelect = (props: PublisherSelectProps) => {
  const { setDisabled, mode, allowClear, disabled, value, id, defaultValue } =
    props;
  const publisher = useSelector((state: AppState) => state.publisher);
  const [options, setOptions] = useState<any[]>([]);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [mapOptions, setMapOptions] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    getDomainsData();
  }, []);

  const getDomainsData = async () => {
    let response = await getDomains();
    let tmpOptions = [];
    let tmpMapOptions: any = {};
    for (let i = 0; i < response?.length; i++) {
      tmpMapOptions[response[i].pub_name] = {
        pub_id: response[i].pub_id,
        public_key: response[i].public_key,
      };
      tmpOptions.push({
        label: response[i].pub_name,
        value: response[i].pub_name,
      });
    }
    setOptions(tmpOptions);
    setMapOptions(tmpMapOptions);
  };

  const onSelect = (value: any) => {
    if (value != "") setDisabled && setDisabled(false);
    let key = mapOptions[value].pub_id;
    let public_key = mapOptions[value].public_key;
    let pub_name = value;
    let data = { key, public_key, pub_name };
    dispatch({ type: "SET_PUBLISHER", payload: data });
    props.onSelect &&
      props.onSelect(mapOptions[value].pub_id, mapOptions[value].public_key);
  };

  return (
    <div style={{ width: "100%" }}>
      {options.length === 0 && (
        <Skeleton.Input active style={{ width: "100%" }} size="large" />
      )}

      {options.length > 0 && (
        <Select
          disabled={disabled}
          allowClear={allowClear}
          placeholder="Please select publisher"
          value={
            mode === "new" || isClear
              ? undefined
              : mode === "duplicate" || mode === "edit"
              ? value
              : publisher?.pub_name
          }
          onClear={() => {
            props.onSelect && props.onSelect(undefined, undefined);
            setIsClear(true);
          }}
          onSelect={onSelect}
          options={options}
          showSearch
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          {...(id && { id })}
          {...(defaultValue && { defaultValue: defaultValue })}
        />
      )}
    </div>
  );
};

export default PublisherSelect;
