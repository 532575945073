import { useState } from "react";
import Modal from "../components/Modal/Modal";
import { Col, Image, Row } from "antd";
import warningLightIcon from "../assets/images/warninglight.svg";
import warningDarkIcon from "../assets/images/warningdark.svg";
import { AppState } from "../store/store";
import { useSelector } from "react-redux";
import Button from "../components/Button";
import "./styles.scss";

interface LogOutlProps {
  onClose: () => void;
  isModalOpen: boolean;
  onOk: () => void;
  alertText: string;
  titleOnOK: string;
  titleOnCancle: string;
}

const AlertModal = (props: LogOutlProps) => {
  const { onClose, isModalOpen, onOk, alertText, titleOnOK, titleOnCancle } =
    props;
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  return (
    <Modal
      title=""
      isModalOpen={isModalOpen}
      setIsModalOpen={onClose}
      width={350}
      hideCncelButton
      hideOkButton
    >
      <Col className="alert">
        <br />
        <Image
          src={isDarkMode ? warningDarkIcon : warningLightIcon}
          preview={false}
        />
        <br />
        <h2 style={{ textAlign: "center", fontSize: 16 }}>{alertText}</h2>
        <br />
        <Row className="buttons-wrapper">
          <Button
            title={titleOnCancle}
            type="default"
            onClick={onClose}
            className={isDarkMode ? "cancel-dark" : "cancel-light"}
          ></Button>
          <Button
            title={titleOnOK}
            type="primary"
            onClick={onOk}
            className={isDarkMode ? "ok-dark" : "ok-light"}
          ></Button>
        </Row>
      </Col>
    </Modal>
  );
};

export default AlertModal;
