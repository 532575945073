export const COLORS = {
  labels: {
    pink: "#FF4097",
    border_primary: "#40AFFF",
    yellow: "#FFA840",
  },
  darkMode: {
    main: "#da9f13",
    yellow: "#FFA840",
    background: "#141414",
  },
  lightMode: {
    buttons: "#262626",
  },
  tags: ["#FF4097", "#FFA840", "#40AFFF", "#5B40FF", "#AB40FF"],
  buttons: {
    surface_brand: "#4097FF",
  },
  white: "white",
  black: "black",
  antd: {
    warning: "#ffe58f",
    error: "#ffccc7",
  },
};
