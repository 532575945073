import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Col, InputNumber, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import Select from "../../components/Select/Select";
import { AppState } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { getLayouts } from "../../api/services/Unit";
import { IDS } from "../../utils/elementsIds";

const UnitPreview = () => {
  const [script, setScript] = useState<string>("");
  const [layout, setLayout] = useState<number>();
  const [layoutsList, setLayoutsList] = useState<number[]>([]);
  const [mode, setMode] = useState<string>("Desktop");
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  useEffect(() => {
    getLayoutsData();
  }, []);

  const getLayoutsData = async () => {
    let layouts = await getLayouts();
    setLayoutsList(layouts);
  };

  const getScript = () => {
    const iframe = document.createElement("iframe");
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "none";

    let scriptr = script;
    scriptr = scriptr.replace("<script", "\x3Cscript");
    scriptr = scriptr.replace("</script>", "\x3C/script>");
    scriptr = scriptr.replace("&rdn=", `&preview=true&layout=${layout}&rdn=`);

    scriptr = scriptr.replace(
      "&rdn=",
      `&ismobile=${mode == "Mobile" ? true : false}&rdn=`
    );

    //remove on prod
    scriptr = scriptr.replace(
      "route.carambo.la/inimage/getlayer",
      `cdata.carambo.la/Layer/InImage/Prod/cbola_platform/version_0.105/js/Carambola_layer_${layout}.min.js`
    );

    let result = document.getElementById("script");
    if (result) {
      result.innerHTML = "";
    }
    result?.appendChild(iframe);
    const doc = iframe.contentDocument;
    if (doc) {
      doc.open();
      doc.write(
        `<head></head>
      <body>
        ${scriptr}
      </body>`
      );
      doc.close();
    }
  };

  const onChangeLayout = (value: number) => {
    setLayout(value);
  };
  return (
    <Row
      style={{
        width: "100%",
        display: "flex",
        gap: 32,
        padding: "40px",
      }}
    >
      <Row
        style={{
          width: "100%",
          gap: 32,
        }}
      >
        <Col span={24}>
          <TextArea
            value={script}
            onChange={(e) => setScript(e.target.value)}
            placeholder="Paste your script here"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              display: "flex",
              height: 300,
            }}
          />
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
              gap: 24,
            }}
          >
            <h2
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: 16,
                color: isDarkMode ? "#FDB813" : "black",
              }}
            >
              Layout:
            </h2>
            <Select
              id={IDS.UNIT_PREVIEW.LAYOUT_BUTTON}
              style={{ width: "10%" }}
              options={layoutsList.map((layout: any) => {
                return { value: layout.id, label: layout.id };
              })}
              onChange={(value: number) => {
                onChangeLayout(value);
              }}
              value={layout?.toString()}
            />

            <Select
              id={IDS.UNIT_PREVIEW.MODE}
              style={{ width: "20%" }}
              value={mode}
              options={[
                { label: "Mobile", value: "Mobile" },
                { label: "Desktop", value: "Desktop" },
              ]}
              onChange={(value) => {
                setMode(value);
              }}
            />

            <Button
              id={IDS.UNIT_PREVIEW.PREVIEW_BUTTON}
              title="Preview"
              disabled={!layout}
              onClick={() => {
                getScript();
              }}
              type="primary"
            />
          </Row>

          <Col
            span={10}
            style={{
              height: "100%",
              border: "none",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 24,
            }}
            id="script"
          ></Col>
        </Col>
      </Row>
    </Row>
  );
};

export default UnitPreview;
