import React, { useEffect, useState } from "react";
import { Card as CardAntD, Skeleton } from "antd";
import "./styles.scss";

interface PureCardProps {
  children: React.ReactNode;
  isLoading?: boolean;
  isInTable?: boolean;
}

const PureCard = (props: PureCardProps) => {
  const { children, isLoading, isInTable } = props;

  return (
    <CardAntD style={{ width: "100%" }} className={isInTable? "table-card": "pure-card"}>
      <Skeleton loading={isLoading} avatar active>
        {children}
      </Skeleton>
    </CardAntD>
  );
};
export default PureCard;
