export const DuplicateIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_242_14406)">
        <path
          d="M12.4546 0H2.63638C1.73226 0 1 0.73226 1 1.63638V13.0909H2.63638V1.63638H12.4546V0Z"
          fill={color}
        />
        <path
          d="M14.9093 3.27272H5.90933C5.00525 3.27272 4.27295 4.00498 4.27295 4.90909V16.3637C4.27295 17.2677 5.00521 18 5.90933 18H14.9093C15.8134 18 16.5457 17.2678 16.5457 16.3637V4.90909C16.5457 4.00502 15.8134 3.27272 14.9093 3.27272ZM14.9093 16.3637H5.90933V4.90909H14.9093V16.3637Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_242_14406">
          <rect width="18" height="18" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
